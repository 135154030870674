import React from "react";

import Input from "../../../shared/Input/Input";
import TextArea from "../../../shared/TextArea/TextArea";

import "./PrizeDetails.scss";
import UploadImage from "../../../shared/UploadImage/UploadImage";
import {isValidJsonString, toBase64} from "../../../../util/util";

type Props = {
    data: any;
    handleOnChange: Function;
    errors: any;
}
const PrizeDetails = ({data, handleOnChange, errors}: Props) => {
    const handleImageChange = (event: any) => {
        let file = event.target.files[0];
        const name = event.target.name;
        toBase64(file)
            .then(result => {
                const image = JSON.stringify({"filename": file.name, "base64": result});
                handleOnChange({...data, [name]: image});
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleTextChange = (event: any) => {
        let value = event.target.value;
        const name = event.target.name;
        if (event.target.name === "daysValid") {
            value = Math.abs(Number(event.target.value));
        }
        handleOnChange({...data, [name]: value});
    }

    const parseImage = (image: string) => {
        if (isValidJsonString(image)) {
            return JSON.parse(image).base64;
        }
        return image ?? "";
    }

    return (
        <div className="prize-details">
            <div className="prize-details__left">
                <UploadImage name="resultsImage" text={'Upload Results\nimage'} onChange={handleImageChange}
                             height={256} width={256} preview={parseImage(data.resultsImage)}
                             title="Recommended image resolution is between: 256x256 and 400x400 pixels"/>

                <UploadImage name="gameImage" text="Upload Game image"
                             height={186} width={136} onChange={handleImageChange} preview={parseImage(data.gameImage)}
                             title="Recommended image resolution: 128x128 or 192x192 pixels"/>
            </div>
            <div className="prize-details__right">
                <div className="prize-details__right--input">
                    <div className="input-group">
                        <Input placeholder="ID" name="internalPrizeId" type="text" onChange={handleTextChange}
                               value={data.internalPrizeId || ''}/>
                    </div>
                    <div className="input-group">
                        <Input placeholder="Title" name="title" type="text" onChange={handleTextChange}
                               value={data.title || ''} errors={errors.title}/>
                        {errors.title ? (
                            <div className="error-message">Title is a required field.</div>
                        ) : ("")}
                    </div>
                </div>
                <div className="prize-details__right--input">
                    <TextArea placeholder="Prize description" name="description" onChange={handleTextChange}
                              value={data.description || ''}/>
                </div>
                <div className="prize-details__right--input">
                    <TextArea placeholder="Optional text (e.g legal note)..." name="legalNotes"
                              onChange={handleTextChange} value={data.legalNotes || ''}/>
                </div>

                <div className="prize-details__right--input">
                    <div className="input-group">
                        <span>Prize validity</span>
                        <Input placeholder="0"
                               name="daysValid"
                               type="number"
                               onChange={handleTextChange}
                               value={data.daysValid || 0}
                               min="0"
                               max="10"
                               errors={errors.daysValid}
                        />
                        <span>days</span>
                        {errors.daysValid ? (
                            <div className="error-message">Prize validity is a required field.</div>
                        ) : ("")}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrizeDetails;
