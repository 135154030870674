import React, {useEffect, useState} from "react";

import "./Notification.scss";
import {useAppDispatch, useAppSelector} from "../../../data/hooks";
import {ReactComponent as Close} from "../../../assets/icons/close.svg";
import {modal} from "../../../data/slices/notificationSlice";

const initialNotificationState = {
    type: "",
    message: ""
}

const Notifcation = () => {
    const data = useAppSelector((state: any) => state.notifications);
    const [notification, setNotification] = useState(initialNotificationState);
    const [show, setShow] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (data.notifications.length > 0) {
            setNotification(data.notifications[data.notifications.length - 1]);
            setShow(true);
            setTimeout(() => {
                setShow(false);
                dispatch(modal.emptyNotifications());
            }, 4000);
        }
    }, [data, dispatch]);

    const onClose = () => {
        setShow(false);
    };


    return show ? (
        <div className={`notification ${notification.type}`}>
            <div className="notification--message">
                <p>{notification.message}</p>
            </div>
            <Close onClick={onClose}/>
        </div>
    ) : null
};

export default Notifcation;
