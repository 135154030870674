import {api} from "./api";
import {SearchPools} from "../../interfaces/SearchPools";
import {Pool} from "../../interfaces/Pool";


export const searchPools = (data: SearchPools) => {
    return api.post("prize-management/pools", data)
        .then((result) => result)
}

export const getPools = (campaignId: string) => {
    return api.get(`prize-management/campaigns/${campaignId}/pools`)
        .then((result) => result)
}

export const getPoolsByCompany = (companyId: string) => {
    return api.get(`prize-management/companies/${companyId}/pools`)
        .then((result) => result)
}

export const createPool = (data: Pool) => {
    return api.post("prize-management/pools/create", data)
        .then((result) => result);
}

export const getPool = (id: number) => {
    return api.get(`prize-management/pools/${id}`)
        .then((result) => result)
}

export const putPool = (data: Pool) => {
    return api.put(`prize-management/pool/${data.id}`, data)
        .then((result) => result);
}

export const deletePool = (id: number) => {
    return api.delete(`prize-management/pools/delete/${id}`)
        .then((result) => result);
}

export const copyPool = (id: number, payload: { copyCodes?: boolean }) => {
    return api.post<{ data: Pool }>(`prize-management/pools/${id}/copies`, payload)
        .then(({ data }) => data.data);
}
