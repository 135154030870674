import React from "react";

import UploadImage from "../../../shared/UploadImage/UploadImage";
import Input from "../../../shared/Input/Input";
import TextArea from "../../../shared/TextArea/TextArea";
import "./PoolDetails.scss";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import {isValidJsonString, toBase64} from "../../../../util/util";
import {Pool} from "../../../../interfaces/Pool";
import {POOL_ACTIVE, POOL_DRAFT, POOL_INACTIVE} from "../../../../constants/constants";

const options = [
    {
        id: POOL_INACTIVE,
        label: "Inactive"
    },
    {
        id: POOL_ACTIVE,
        label: "Active"
    },
    {
        id: POOL_DRAFT,
        label: "Draft"
    }
];

type Props = {
    pool: Pool,
    handleDataChange: Function,
    errors: any
}

const PoolDetails = ({pool, handleDataChange, errors}: Props) => {
    const handleImageChange = (event: any) => {
        let file = event.target.files[0];
        toBase64(file)
            .then(result => {
                const image = JSON.stringify({filename: event.target.files[0].name, base64: result});
                handleDataChange({...pool, image});
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleInputChange = (event: any) => {
        let value = event.target.value;
        const name = event.target.name;
        if (event.target.name === "durationInDays") {
            value = Math.abs(Number(event.target.value));
        }
        handleDataChange({...pool, [name]: value});
    }

    const handleStatusChange = (e: any) => {
        handleDataChange({...pool, status: e.id});
    }

    const getSelectedStatus = () => {
        return options.filter((option) => option.id === pool.status)[0];
    }

    const parseImage = (image: string | null) => {
        if (image && isValidJsonString(image)) {
            return JSON.parse(image).base64;
        }
        return image ?? "";
    }

    return (
        <ErrorBoundary>
            <div className="pool-details">
                <div className="pool-details__left">
                    <UploadImage
                        text="Upload results image"
                        name="image"
                        height={210}
                        onChange={handleImageChange}
                        preview={parseImage(pool.image)}
                    />
                </div>
                <div className="pool-details__right">
                    <div className="pool-details__right--input">
                        <div className="input-group">
                            <Input placeholder="ID" name="internalPoolId" type="text" onChange={handleInputChange}
                                   value={pool.internalPoolId ? pool.internalPoolId : ""}/>
                        </div>
                        <div className="input-group">
                            <Input placeholder="Title" name="title" type="text" onChange={handleInputChange}
                                   value={pool.title || ''} errors={errors.title}/>
                            {errors.title ? (
                                <div className="error-message">Title is a required field.</div>
                            ) : ("")}
                        </div>
                    </div>
                    <div className="pool-details__right--input">
                        <TextArea
                            placeholder="Pool description"
                            name="description"
                            onChange={handleInputChange}
                            value={pool.description}
                        />
                    </div>


                    <div className="pool-details__right--bottom">
                        <div className="pool-details__right--bottom-input">
                        </div>
                        <div className="pool-details__right--bottom-input">
                            <div className="input-group">
                                <span>Duration</span>
                                <Input
                                    placeholder="0"
                                    name="durationInDays"
                                    type="number"
                                    onChange={handleInputChange}
                                    value={pool.durationInDays}
                                    min="0"
                                    errors={errors.durationInDays}
                                />
                                <span>days</span>
                                {errors.durationInDays ? (
                                    <div className="error-message">Duration is a required field.</div>
                                ) : ("")}
                            </div>
                        </div>

                        <div className="pool-details__right--bottom-input">
                            <span>Pool Status</span>
                            <Dropdown
                                data={options}
                                handleChange={handleStatusChange}
                                selectedValue={getSelectedStatus()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
}

export default PoolDetails;
