import {Dialog} from "@headlessui/react";
import React from "react";

import {ReactComponent as Close} from "../../../assets/icons/close.svg";
import "./Modal.scss";

type Props = {
    isOpen: boolean,
    setIsOpen: Function,
    title: string,
    children: React.ReactNode,
    customClass?: string
}

const Modal = ({isOpen, setIsOpen, title, children, customClass}: Props) => {
    return (
        <Dialog as="div" open={isOpen} onClose={() => setIsOpen} className={`modal ${customClass}`}>

            <div className="modal__backdrop " aria-hidden="true"/>

            <div className="modal__container">

                <Dialog.Panel className="modal__panel">
                    <Dialog.Title>
                        {title}
                        <div className="modal__close" onClick={() => setIsOpen(!isOpen)}>
                            <Close/>
                        </div>
                    </Dialog.Title>
                    <Dialog.Description as="div" className="modal__description">
                        {children}
                    </Dialog.Description>
                </Dialog.Panel>

            </div>
        </Dialog>
    )
};

export default Modal;
