import React, {useEffect, useState} from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import Home from "./pages/Home/Home";
import WinnerSearch from "./pages/WinnerSearch/WinnerSearch";
import ProtectedRoute from "./components/shared/ProtectedRoute/ProtectedRoute";
import {parseJwt} from "./util/util";
import TokenService from "./auth/TokenService";
import NotFound from "./pages/NotFound/NotFound";

const App = () => {
    const [token] = useState(TokenService.getCookieAccessToken());

    useEffect(() => {
        if (token.length > 0) {
            const parsedToken = parseJwt(token);
            localStorage.setItem("companyId", parsedToken.company_id);
        }
    }, [token])

    return (
        <BrowserRouter basename="/prize-management">
            <div className="App">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute
                                token={token}
                                authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
                                outlet={<Home/>}
                            />
                        }
                    />
                    <Route path="/search-winner/:campaignId" element={<WinnerSearch/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
