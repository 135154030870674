import {Prize} from "./Prize";
import {WinProbability} from "./WinProbability";
import {PrizeLimit} from "./PrizeLimit";

export interface Pool {
    campaignIds: Array<string>
    createdAt: string
    description: string
    durationInDays: number
    id: number | null,
    internalPoolId: string,
    image: string
    labels: Array<string>
    notes: Array<string>
    perPlay: number
    prizeLimitConstant: number
    prizeLimitType: number,
    prizeLimitPerDay: Array<PrizeLimit>
    prizes: Array<Prize>
    status: number
    title: string
    updatedAt: string
    winProbabilityType: number,
    winProbabilityPerDay: Array<WinProbability>,
    wins: number,
    amount: number,
    available: number,
    sentOut: number,
    redeemed: number,
    companyId: string,
}

export class PoolFactory {
    static getInitialData(): Pool {
        return {
            campaignIds: [],
            createdAt: "",
            description: "",
            durationInDays: 25,
            id: null,
            internalPoolId: "",
            image: "",
            labels: [],
            notes: [],
            perPlay: 100,
            prizeLimitConstant: 0,
            prizeLimitType: 0,
            prizeLimitPerDay: [],
            prizes: [],
            status: 2,
            title: "",
            updatedAt: "",
            winProbabilityType: 0,
            winProbabilityPerDay: [],
            wins: 0,
            amount: 0,
            available: 0,
            sentOut: 0,
            redeemed: 0,
            companyId: localStorage.getItem("companyId") ?? "",
        }
    }
}


