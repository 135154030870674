import React, {Fragment} from "react";

import Prizes from "../../components/features/prizes/Prizes/Prizes";
import PrizePools from "../../components/features/prize-pools/PrizePools/PrizePools";
import Tabs from "../../components/shared/Tabs/Tabs";

import "./Home.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Notification from "../../components/shared/Notification/Notification";

const Home = () => {
    const tabs = [
        {
            title: "Prizes",
            component: <Prizes/>
        },
        {
            title: "Prize pools",
            component: <PrizePools/>
        }
    ]
    return (
        <Fragment>
            <Navbar></Navbar>
            <Notification/>

            <div className="container">
                <Tabs tabs={tabs} type="big"/>
            </div>
        </Fragment>
    );
};

export default Home;
