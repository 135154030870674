import React, {ChangeEventHandler, useState} from "react";

import "./DoubleInput.scss";

type Props = {
    firstValue: number,
    handleFirstValueChange: ChangeEventHandler<HTMLInputElement>,
    secondValue: number,
    handleSecondValueChange: ChangeEventHandler<HTMLInputElement>,
    placeholders?: Array<string>
}

const DoubleInput = (props: Props) => {
    const {firstValue, secondValue, handleFirstValueChange, handleSecondValueChange, placeholders} = props;
    const [focused, setIsFocused] = useState(false);
    return (
        <div
            className={`double-input-wrapper ${focused ? "focused" : ""}`}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            <input
                type="number"
                name="firstValue"
                value={firstValue}
                onChange={handleFirstValueChange}
                aria-label="firstValue"
                min={1}
                max={10000}
                className="double-input"
                placeholder={placeholders && placeholders[0] ? placeholders[0] : ""}
            />
            <span>/</span>
            <input
                type="number"
                name="secondValue"
                value={secondValue}
                onChange={handleSecondValueChange}
                aria-label="secondValue"
                min={1}
                max={10000}
                className="double-input"
                placeholder={placeholders && placeholders[0] ? placeholders[0] : ""}
            />
        </div>
    );
};

export default DoubleInput;
