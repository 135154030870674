import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";

import "./BatchesUpload.scss";
import {ReactComponent as Calendar} from "../../../../assets/icons/calendar.svg";
import Input from "../../../shared/Input/Input";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import DatePicker from "react-datepicker";
import {Option} from "../../../../interfaces/Option";
import FileUpload from "../../../shared/FileUpload/FileUpload";

type Props = {
    onChange: Function;
    data: any;
    errors: any;
}

type State = {
    file: string,
    date: Date | null,
}

const options = [
    {
        id: "EAN128",
        label: "EAN128"
    },
    {
        id: "EAN13",
        label: "EAN13"
    },
    {
        id: "QR",
        label: "QR Code"
    }
];

class BatchesUpload extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            file: "",
            date: this.props.data.expiration
        };

        this.onFileChange = this.onFileChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleBarCodeChange = this.handleBarCodeChange.bind(this);
        this.getSelectedCodeType = this.getSelectedCodeType.bind(this);
    }


    onFileChange(event: any) {
        const files = event.target.files;
        if (files[0].type !== "text/csv") {
            return;
        }
        this.setState({file: files[0].name});
        this.props.onChange({...this.props.data, "filename": files[0]});
    }

    onInputChange(event: any) {
        let value = event.target.value;
        const name = event.target.name;
        if (["codeValidity"].includes(event.target.name)) {
            value = Math.abs(Number(event.target.value));
        }
        this.props.onChange({...this.props.data, [name]: value});
    }

    handleDateChange(event: Date | null) {
        this.setState({date: event})
        this.props.onChange({...this.props.data, "expiration": event});
    }

    handleBarCodeChange(option: Option) {
        this.props.onChange({...this.props.data, "codeType": option.id});
    }

    getSelectedCodeType() {
        return options.filter((option) => option.id === this.props.data.codeType)[0] ?? options[0];
    }

    render() {
        return (
            <div className="codes-upload">
                <div className="codes-upload__top">
                    <FileUpload
                        file={this.state.file}
                        error={this.props.errors.filename}
                        handleChange={this.onFileChange}
                    />
                    <div className="codes-dropdown">
                        <p className="codes-upload__label">Code Type</p>
                        <Dropdown
                            data={options}
                            selectedValue={this.getSelectedCodeType()}
                            handleChange={this.handleBarCodeChange}
                        />
                    </div>
                </div>
                <div className="codes-upload__bottom">
                    <div className="codes-expiration">
                        <p className="codes-upload__label">Code Expiration</p>
                        <Calendar/>
                        <DatePicker
                            selected={this.state.date}
                            onChange={(e) => this.handleDateChange(e)}
                        />
                    </div>

                    <div className="codes-validity">
                        <p className="codes-upload__label">Code Validity</p>
                        <Input placeholder="0" name="codeValidity" type="number" min="0" errors={this.props.errors.codeValidity}
                               value={this.props.data.codeValidity ?? 0} onChange={this.onInputChange}/>
                        <span>minutes</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default BatchesUpload;
