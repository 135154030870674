import React, {MouseEventHandler} from "react";

import "./Button.scss";
import {ReactComponent as Spinner} from "../../../assets/icons/spinner.svg";

type Props = {
    type: "primary" | "secondary" | "danger",
    onClick: MouseEventHandler<HTMLButtonElement>,
    children: React.ReactNode,
    isLoading?: boolean,
    disabled?: boolean,
    customClass?: string,
}

const Button = (props: Props) => {
    return (
        <button
            className={`btn ${props.type} ${props.customClass ? props.customClass : ""}`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {
                props.isLoading ? (
                    <Spinner/>
                ) : props.children
            }
        </button>
    );
};

export default Button;
