import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import InCampaignTable from "../InCampaignTable/InCampaignTable";
import "./InCampaign.scss";
import {Pool} from "../../../../interfaces/Pool";
import SearchAutoComplete from "../../../shared/SearchAutoComplete/SearchAutoComplete";
import {useAppDispatch, useAppSelector} from "../../../../data/hooks";
import {emptyCampaigns, fetchCampaigns} from "../../../../data/slices/campaignsSlice";
import {SearchCampaigns} from "../../../../interfaces/SearchCampaigns";
import {Campaign} from "../../../../interfaces/Campaign";
import {Option} from "../../../../interfaces/Option";
import Button from "../../../shared/Button/Button";
import {emptyCampaign, fetchCampaign} from "../../../../data/slices/campaignSlice";
import {STATUS_LOADING} from "../../../../constants/constants";

const initialRequestData: SearchCampaigns = {
    page: 0,
    size: 40,
    companyId: localStorage.getItem("companyId") ?? "",
    orderBy: "createdDate",
    direction: "DESC",
    name: "",
}

type Props = {
    pool: Pool,
    handleDataChange: Function
}

const campaignOption = {
    id: "",
    label: ""
}

const InCampaign = ({pool, handleDataChange}: Props) => {
    const campaigns = useAppSelector((state: any) => state.campaigns.data);
    const campaign = useAppSelector((state: any) => state.campaign.data);
    const status = useAppSelector((state: any) => state.campaign.status);
    const dispatch = useAppDispatch();

    const [requestData, updateRequestData] = useState(initialRequestData);
    const [selectedCampaign, setSelectedCampaign] = useState(campaignOption);
    const [isAddingAllowed, setIsAddingAllowed] = useState(false);

    useEffect(() => {
        if (requestData.name.length > 2) {
            dispatch(fetchCampaigns(requestData));
        } else {
            dispatch(emptyCampaigns());
        }
        const hasCampaigns = pool && pool.campaignIds.length > 0;
        if (hasCampaigns) {
            dispatch(fetchCampaign(pool.campaignIds[0]));
        }
        setIsAddingAllowed(!hasCampaigns);
    }, [dispatch, pool, requestData])

    const getParsedCampaigns = () => {
        return campaigns.map((campaign: Campaign) => {
            return {
                id: campaign.id,
                label: campaign.name
            }
        });
    }

    const search = (e: any) => {
        updateRequestData({...requestData, name: e.target.value});
    }

    const handleCampaignChange = (id: string) => {
        const campaign = getParsedCampaigns().find((campaign: Option) => campaign.id === id);
        if (campaign) {
            setSelectedCampaign(campaign);
        }
    }

    const addPoolToCampaign = () => {
        if (selectedCampaign && !pool.campaignIds.includes(selectedCampaign.id)) {
            handleDataChange({...pool, campaignIds: [...pool.campaignIds, selectedCampaign.id]});
            setSelectedCampaign(campaignOption);
            dispatch(emptyCampaigns());
        }
    }

    const getCampaigns = () => {
        // currently only one pool per campaign, may change in the future
        if (campaign) {
            return [campaign];
        }
        return [];
    }

    const getCampaignId = () => {
        if (campaign) {
            return campaign.id;
        }
        return null;
    }

    const removeCampaign = (campaignId: string) => {
        handleDataChange({...pool, campaignIds: []});
        dispatch(emptyCampaign());
    }

    return (
        <div className="in-campaign">
            <div className="in-campaign__header">
                <SearchAutoComplete
                    options={getParsedCampaigns()}
                    selectedOption={selectedCampaign}
                    handleSelectChange={handleCampaignChange}
                    handleSearch={search}
                    searchValue={requestData.name}
                    disabled={!isAddingAllowed}
                    placeholder={"Search campaigns"}
                />

                <Button type="primary" disabled={!isAddingAllowed || !selectedCampaign.id} onClick={addPoolToCampaign}>
                    Add pool to campaign
                </Button>
            </div>
            <div className="in-campaign__body">
                <InCampaignTable
                    data={getCampaigns()}
                    pool={pool}
                    removeCampaign={removeCampaign}
                    isLoading={status === STATUS_LOADING}
                />
                {
                    getCampaignId() ? (
                        <Link to={`/search-winner/${getCampaignId()}`} target="_blank">Search for the winner</Link>
                    ) : null
                }
            </div>
        </div>
    );
};

export default InCampaign;
