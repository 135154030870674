import React from "react";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableBody from "../../../shared/Table/TableBody";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableRow from "../../../shared/Table/TableRow";
import TableCell from "../../../shared/Table/TableCell";
import {Column} from "../../../../interfaces/Column";
import {ReactComponent as Delete} from "../../../../assets/icons/delete.svg";
import {Prize} from "../../../../interfaces/Prize";
import Switch from "../../../shared/Switch/Switch";
import {updatePrize} from "../../../../data/slices/prizeSlice";
import {useAppDispatch} from "../../../../data/hooks";
import {stripHtmlTags} from "../../../../util/util";

type Props = {
    data: Array<Prize>,
    openDeleteAlert: Function,
    toggleEnabled: Function
}

const InsidePoolTable: React.FC<Props> = ({data, openDeleteAlert, toggleEnabled}: Props) => {
    const dispatch = useAppDispatch();

    const columns: Array<Column> = [
        {
            label: "Enabled",
            infoText: <span>Clicking these switches will<br/> automatically update the prizes</span>,
            value: "active",
            render: (data, columnValue) => (
                    <Switch
                        active={!data[columnValue]}
                        handleChange={toggleSwitch}
                        data={data}
                    />
            )
        },
        {
            label: "Title",
            value: "title",
        },
        {
            label: "Image",
            value: "gameImage",
            render: (data, columnValue) => (
                <img src={data[columnValue]} alt="Prize"/>
            )
        },
        {
            label: "Description",
            value: "description",
            render: (data: any, columnValue) => (
                <span
                    className="elipsis-200"
                    title={stripHtmlTags(data[columnValue])}
                >
                    {stripHtmlTags(data[columnValue])}
                </span>
            )
        },
        {
            label: "Amount",
            value: "amount"
        },
        {
            label: "Avaliable",
            value: "available"
        },
        {
            label: "",
            value: "delete",
            render: (data) => (
                <Delete onClick={() => openDeleteAlert(data.id)}/>
            )
        },
    ];

    const toggleSwitch = (active: boolean, data: Prize) => {
        if (data && data.id) {
            const prize = {...data};
            prize.active = active ? 0 : 1;
            prize.amount = data.amount === null ? 0 : data.amount;
            dispatch(updatePrize(prize, data.id));
            toggleEnabled(active, data.id);
        }
    }

    const renderTableCell = (row: any, column: any, index: number) => {
        return (
            <TableCell key={index}>
                {
                    column.render ? (
                        column.render(row, column.value)
                    ) : (
                        <span className="elipsis-200">
                            {row[column.value]}
                        </span>
                    )
                }
            </TableCell>
        )
    }

    return (
        <ErrorBoundary>
            <Loader isLoading={false}>
                <Table wrapperClassName="with-scroll">
                    <TableHead>
                        {columns.map(({label, infoText}, index) => <TableHeadCell
                                key={index}
                                heading={label}
                                infoText={infoText ? infoText : ""}
                            />
                        )}
                    </TableHead>
                    <TableBody>
                        {
                            data.length > 0 ? (
                                data.map((row, index) => (
                                    <TableRow key={index}>
                                        {
                                            columns.map((column, i) => (
                                                renderTableCell(row, column, i)
                                            ))
                                        }
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <td className="no-data" colSpan={columns.length}>No data</td>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </Loader>
        </ErrorBoundary>
    );
};

export default InsidePoolTable;
