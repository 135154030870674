import { CommonCodeBatch } from "../interfaces/Batch";
import { nYearsFromNow } from "../util/util";

/**
 * Methods
 */
export const METHOD_ADD = "add";
export const METHOD_DELETE = "delete";
export const METHOD_EDIT = "edit";

/**
 * Pool status
 */
export const POOL_INACTIVE = 0;
export const POOL_ACTIVE = 1;
export const POOL_DRAFT = 2;

/**
 * Prize title
 */
export const NO_WIN = "No Win";

/**
 * Prize limit type
 */
export const LIMIT_SAME = 0;
export const LIMIT_DIFFERENT = 1;
export const LIMIT_UNLIMITED = 2;

/**
 * Probability type
 */
export const PROBABILITY_SAME = 0;
export const PROBABILITY_DIFFERENT = 1;

/**
 * Double input values
 */
export const FIRST_VALUE = "firstValue";
export const SECOND_VALUE = "secondValue";

/**
 * API state status
 */
export const STATUS_IDLE = "idle";
export const STATUS_LOADING = "loading";
export const STATUS_FAILED = "failed";

/** Prize code type  */
export const prizeCode = {
    NO_CODE: 'NO_CODE',
    INDIVIDUAL_CODE: 'INDIVIDUAL_CODE',
    COMMON_CODE: 'COMMON_CODE'
} as const;

/** barcode type  */
export const barcodeType = {
    EAN13: 'EAN13',
    EAN128: 'EAN128',
    QR: 'QR'
} as const;

export const DEFAULT_COMMON_CODE_BATCH: CommonCodeBatch = {
    id: null,
    batchType: prizeCode.COMMON_CODE,
    codeType: barcodeType.EAN128,
    codeValidity: 15,
    expiration: nYearsFromNow(1).toISOString(),
    commonCode: '',
    codesAmount: 10
};

export const TIME_ZONE_STORAGE_KEY = 'TIME_ZONE';
