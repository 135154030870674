import {apiOld} from "./api";
import {SearchCampaigns} from "../../interfaces/SearchCampaigns";

export const getCampaigns = (data: SearchCampaigns) => {
    const params = {
        page: data.page,
        size: data.size,
        companyId: data.companyId,
        orderBy: data.orderBy,
        direction: data.direction,
        name: data.name,
    };
    return apiOld.get(`campaign/search`, {params})
        .then((result) => result)
}

export const getCampaign = (campaignId: string) => {
    return apiOld.get(`campaign/${campaignId}`)
        .then((result) => result)
}
