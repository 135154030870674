import TokenService from "./TokenService";

class AuthService {
    logout() {
        TokenService.deleteCookieAccessToken();
        TokenService.deleteCookieRefreshToken();
        window.location.replace(process.env.REACT_APP_AUTHENTICATION_URL || 'https://brame.ch/');
    }
}

export default new AuthService();