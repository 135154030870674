import React, {ChangeEventHandler} from "react";

import "./TextArea.scss";

type Props = {
    placeholder: string,
    name: string,
    onChange: ChangeEventHandler<HTMLTextAreaElement>,
    value?: string|number
}

const TextArea = ({placeholder, name, onChange, value}: Props) => {
    return (
        <textarea className="textarea" placeholder={placeholder} name={name} onChange={onChange} value={value}/>
    );
};

export default TextArea;
