import React from "react";

import "./Radio.scss";

type Props = {
    value: string | number;
    selectedValue: string | number;
    label?: string;
    handleChange: Function
}

const Radio = ({value, selectedValue, label, handleChange}: Props) => {

    const isChecked = () => {
        return selectedValue === value;
    }

    return (
        <div
            className="radio"
            onClick={() => handleChange(value)}
        >
            <div
                className={`radio-outer-circle ${!isChecked() ? "unselected" : ""}`}
            >
                <div
                    className={`radio-inner-circle ${!isChecked() ?
                        "unselected-circle" : ""}`}
                />
            </div>
            <div className="helper-text">{label}</div>
        </div>
    );
};

export default Radio;
