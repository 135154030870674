import {createSlice, Dispatch} from "@reduxjs/toolkit";

import {RootState} from "../store";
import {getPrizes} from "../api/winners";
import {STATUS_FAILED, STATUS_IDLE, STATUS_LOADING} from "../../constants/constants";
import {Prize} from "../../interfaces/Prize";

export interface WinnerPrizesState {
    data: Array<Prize>;
    status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: WinnerPrizesState = {
    data: [],
    status: STATUS_IDLE,
};

const winnerPrizesSlice = createSlice({
    name: "winnerPrizes",
    initialState,
    reducers: {
        searchWinnersStarted: (state) => {
            state.status = STATUS_LOADING;
        },
        searchWinnersSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data;
            } else {
                state.data = initialState.data;
            }

        },
        searchWinnersError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.code, action.payload.message);
        }
    },
});

const {searchWinnersStarted, searchWinnersSuccess, searchWinnersError} = winnerPrizesSlice.actions;

export const selectWinnerPrizes = (state: RootState) => state.winners.data;
export const selectWinnerPrizesStatus = (state: RootState) => state.winners.status;

export default winnerPrizesSlice.reducer;

export const fetchWinnerPrizes = (campaignId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(searchWinnersStarted());
            const result = await getPrizes(campaignId);
            dispatch(searchWinnersSuccess(result.data));
        } catch (error) {
            dispatch(searchWinnersError(error))
        }
    }
}
