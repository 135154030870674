import React from "react";
import "./NotFound.scss";
import Button from "../../components/shared/Button/Button";

const NotFound = () => {

    const handleOnClick = () => {
        window.location.replace("/");
    }

    return (
        <div className="container">
            <div className="not-found">
                <h1>
                    404
                </h1>
                <p>Sorry! The page you were looking for doesn't exist.</p>
                <Button type="primary" onClick={handleOnClick}>
                    Go back to dashboard
                </Button>
            </div>
        </div>
    );
};

export default NotFound;
