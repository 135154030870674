import { useEffect } from 'react';

type RedirectType = {
    authenticationPath: string;
};

function Redirect({ authenticationPath }: RedirectType) {
    useEffect(() => {
        window.location.replace(authenticationPath);
    }, [authenticationPath]);

    return <div />;
}

export default Redirect;
