import React from "react";
import {ReactComponent as AngleRight} from "../../../../assets/icons/angle-right.svg";
import {Prize} from "../../../../interfaces/Prize";

type Props = {
    prize: Prize;
}

const ModalFooterData = ({prize}: Props) => {
    return (
        <>
            <div className="modal__footer--link">
                Info about current prize availability
                <AngleRight/>
            </div>
            <div className="modal__footer--totals">
                <div>
                    <span className="total-name">Amount</span>
                    <span className="total-value">{prize.amount}</span>
                </div>
                <div>
                    <span className="total-name">Redeemed</span>
                    <span className="total-value redeemed">{prize.redeemed}</span>
                </div>
                <div>
                    <span className="total-name">Reserved</span>
                    <span className="total-value reserved">{prize.sentOut}</span>
                </div>
                <div>
                    <span className="total-name">Available</span>
                    <span className="total-value available">{prize.available}</span>
                </div>
            </div>
        </>
    );
};

export default ModalFooterData;
