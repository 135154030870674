import React, {useState} from "react";

import InsidePoolTable from "../InsidePoolTable/InsidePoolTable";
import Button from "../../../shared/Button/Button";
import "./InsidePool.scss";
import Modal from "../../../shared/Modal/Modal";
import PrizesTable from "../PrizesTable/PrizesTable";
import Confirm from "../../../shared/Confirm/Confirm";
import {Pool} from "../../../../interfaces/Pool";
import {SearchPools} from "../../../../interfaces/SearchPools";
import {useAppDispatch} from "../../../../data/hooks";
import {fetchPools} from "../../../../data/slices/poolsSlice";
import {updatePool} from "../../../../data/slices/poolSlice";

type Props = {
    pool: Pool,
    handleDataChange: Function,
    requestData: SearchPools
}

const InsidePool = ({pool, handleDataChange, requestData}: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [prizeId, updatePrizeId] = useState<number | null>(null);
    const [poolPrizes, updatePoolPrizes] = useState(pool.prizes);
    const dispatch = useAppDispatch();

    const openAddModal = () => {
        setIsModalOpen(true);
    }

    const openDeleteAlert = (id: number) => {
        setIsAlertOpen(true);
        updatePrizeId(id);
    }

    const deletePrize = () => {
        const prizes = pool.prizes.filter((prize) => prize.id !== prizeId);
        handleDataChange({...pool, prizes});
        setIsAlertOpen(false);
    }

    const cancel = () => {
        updatePoolPrizes(pool.prizes);
        setIsModalOpen(false)
    }

    const save = () => {
        updatePoolPrizes(poolPrizes);
        handleDataChange({...pool, prizes: poolPrizes});
        setIsModalOpen(false)
    }

    const toggleEnabled = (active: boolean, id: number) => {
        const prizes = poolPrizes.map((prize) => {
            if (prize.id === id) {
                const pr = {...prize};
                pr.active = active ? 0 : 1;
                return pr;
            }

            return prize;
        });

        updatePoolPrizes(prizes);
        handleDataChange({...pool, prizes: prizes});
        const newPool = {...pool};
        newPool.prizes = prizes;
        dispatch(updatePool(newPool)).then(() => {
            dispatch(fetchPools(requestData));
        });
    }

    return (
        <div className="inside-pool">
            <div className="inside-pool__header">
                <Button type="primary" onClick={openAddModal}>
                    Add prize
                </Button>
            </div>
            <div className="inside-pool__body">
                <InsidePoolTable
                    data={pool.prizes}
                    openDeleteAlert={openDeleteAlert}
                    toggleEnabled={toggleEnabled}
                />
            </div>


            <Modal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                title="Add to pool"
            >
                <PrizesTable
                    updatePoolPrizes={updatePoolPrizes}
                    poolPrizes={poolPrizes}
                />
                <div className="modal__footer">
                    <div className="modal__footer--btns">
                        <Button type="secondary" onClick={() => cancel()}>Cancel</Button>
                        <Button type="primary" onClick={() => save()}>Save</Button>
                    </div>
                </div>
            </Modal>

            <Confirm
                isOpen={isAlertOpen}
                setIsOpen={setIsAlertOpen}
                title="Remove prize"
                text="Are you sure you want to remove prize from this pool?"
                buttonText="Yes"
                type="danger"
                action={deletePrize}
            />
        </div>
    );
};

export default InsidePool;
