import Redirect from "../Redirect/Redirect";

interface ProtectedRouteProps {
    authenticationPath?: string;
    outlet: JSX.Element;
    token: string;
}

export default function ProtectedRoute({authenticationPath, outlet, token,}: ProtectedRouteProps) {
    if (token.length > 0) {
        return outlet;
    }
    return (
        <Redirect
            authenticationPath={authenticationPath || 'https://brame.ch/'}
        />
    );
}

