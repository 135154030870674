import React, {ChangeEventHandler} from "react";

import Input from "../Input/Input";
import "./FileUpload.scss";

type Props = {
    file: string,
    error: boolean,
    handleChange: ChangeEventHandler<HTMLInputElement>
}

const FileUpload = ({file, error, handleChange}: Props) => {
    return (
        <div className="file-upload">
            <div className="input-group">
                <Input
                    placeholder="Please select .csv file"
                    name="internalPrizeId"
                    type="text"
                    value={file} readOnly={true} errors={error}
                />
                {
                    error && (
                        <div className="error-message">File is a required field.</div>
                    )
                }
            </div>
            <label>
                <input className="upload-btn" type="file" multiple accept=".csv" onChange={handleChange}/>
                Browse
            </label>
        </div>
    );
};

export default FileUpload;
