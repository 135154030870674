import {api} from "./api";

export const getCodes = (batchId: number, searchTerm: string) => {
    const params = {
        searchTerm: searchTerm
    };
    return api.get(`prize-management/codes/${batchId}`, {params})
        .then((result) => result)
}

export const getCodeById = (codeId: number) => {
    return api.get(`prize-management/code/${codeId}`)
        .then((result) => result)
}
