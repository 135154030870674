import React from "react";
import "./Loader.scss";

type Props = {
    children: JSX.Element,
    isLoading: boolean,
    height?: number
}

const Loader = (props: Props) => {
    if (props.isLoading) {
        return (
            <div className="loader-wrapper" style={{height: props.height + "px"}}>
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
        );
    }

    return props.children;
};

export default Loader;
