import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {searchPrizes} from '../api/prizes';
import {Prize} from "../../interfaces/Prize";
import {RootState} from "../store";
import {STATUS_FAILED, STATUS_IDLE, STATUS_LOADING} from "../../constants/constants";

export interface PrizesState {
    data: Array<Prize>;
    dataCount: number;
    status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: PrizesState = {
    data: [],
    status: STATUS_IDLE,
    dataCount: 0
};

const prizesSlice = createSlice({
    name: "prizes",
    initialState,
    reducers: {
        searchPrizesStarted: (state) => {
            state.status = STATUS_LOADING;
        },
        searchPrizesSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data;
                state.dataCount = action.payload.dataCount;
            } else {
                state.data = initialState.data;
                state.dataCount = initialState.dataCount;
            }

        },
        searchPrizesError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.code, action.payload.message);
        }
    },
});

const {searchPrizesStarted, searchPrizesSuccess, searchPrizesError} = prizesSlice.actions;

export const selectPrizes = (state: RootState) => state.prizes.data;
export const selectDataCount = (state: RootState) => state.prizes.dataCount;
export const selectStatus = (state: RootState) => state.prizes.status;

export default prizesSlice.reducer;

export const fetchPrizes = (data: any) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(searchPrizesStarted());
            const result = await searchPrizes(data);
            dispatch(searchPrizesSuccess(result.data));
        } catch (error) {
            dispatch(searchPrizesError(error))
        }
    }
}
