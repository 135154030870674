import React, {useState} from "react";

import "./InPools.scss";
import Button from "../../../shared/Button/Button";
import InPoolsTable from "../InPoolsTable/InPoolsTable";
import {Prize} from "../../../../interfaces/Prize";
import {Pool} from "../../../../interfaces/Pool";

type Props = {
    data: Prize,
    handleOnChange: Function;
}

const InPools: React.FC<Props> = ({data, handleOnChange}: Props) => {
    const [isOpenAddToPoolModal, setIsAddToPoolModal] = useState(false);

    const handlePoolChange = (pool: Pool) => {
        handleOnChange({...data, pools: [pool]});
        setIsAddToPoolModal(false);
    };

    return (
        <div className="prize-pools">
            <div className="prize-pools__header">
                <Button type="primary" onClick={() => setIsAddToPoolModal(!isOpenAddToPoolModal)} disabled={!data.id}>
                    Add to pool
                </Button>
            </div>

            <div className="prize-pools__body">
                <InPoolsTable
                    data={data.pools}
                    isOpenAddToPoolModal={isOpenAddToPoolModal}
                    setIsAddToPoolModal={setIsAddToPoolModal}
                    handleOnChange={handlePoolChange}
                />
            </div>
        </div>
    );
};

export default InPools;
