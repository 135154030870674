import { Listbox } from "@headlessui/react";
import React, {Fragment} from "react";

import "./Dropdown.scss";
import {ReactComponent as AngleDown} from "../../../assets/icons/angle-down.svg";

type Props = {
    data: Array<{id: string | number; label: string; disabled?: boolean; title?: string}>
    selectedValue: {id: string | number; label: string},
    handleChange: (value: { id: string | number; label: string; }) => void
}

const Dropdown = ({data, selectedValue, handleChange}: Props) => {

    return (
        <Listbox value={selectedValue} onChange={handleChange} as="div" className="dropdown">
            <Listbox.Button className="dropdown__btn">
                {selectedValue.label}
                <AngleDown/>
            </Listbox.Button>
            <Listbox.Options className="dropdown__menu">
                {data.map((item) => (
                    <Listbox.Option key={item.id} value={item} as={Fragment} disabled={item.disabled}>
                        {({ active, selected }) => (
                            <li
                                className={`dropdown__menu--item ${
                                    (active || selected) ? "active" : (item.disabled) ? "disabled" : ""
                                    
                                }`}
                                title={item.title ? item.title : ""}
                            >
                                {item.label}
                            </li>
                        )}
                    </Listbox.Option>
                ))}
            </Listbox.Options>
        </Listbox>
    );
};

export default Dropdown;
