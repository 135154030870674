import React, {useEffect, useState} from "react";
import "./InPoolsAdd.scss";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import {Column} from "../../../../interfaces/Column";
import {useAppDispatch, useAppSelector} from "../../../../data/hooks";
import {fetchPools} from "../../../../data/slices/poolsSlice";
import Radio from "../../../shared/Radio/Radio";
import {Pool} from "../../../../interfaces/Pool";
import {SearchPools} from "../../../../interfaces/SearchPools";
import Table from "../../../shared/Table/Table";
import TableHead from "../../../shared/Table/TableHead";
import TableBody from "../../../shared/Table/TableBody";
import TableRow from "../../../shared/Table/TableRow";
import Pagination from "../../../shared/Pagination/Pagination";
import TableCell from "../../../shared/Table/TableCell";
import Search from "../../../shared/Search/Search";
import {STATUS_LOADING} from "../../../../constants/constants";

type Props = {
    selectedPool: string | number,
    setSelectedPool: Function
}

const initialRequestData: SearchPools = {
    companyId: localStorage.getItem("companyId") ?? "",
    perPage: 8,
    pageNumber: 0,
    sortBy: "createdAt",
    order: "DESC",
    searchTerm: ""
}

const InPoolsAdd: React.FC<Props> = ({selectedPool, setSelectedPool}: Props) => {
    const data = useAppSelector((state: any) => state.pools.data);
    const status = useAppSelector((state: any) => state.pools.status);
    const totalCount = useAppSelector((state: any) => state.pools.dataCount);
    const [requestData, changeRequestData] = useState(initialRequestData);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!requestData) return;
        dispatch(fetchPools(requestData));
    }, [dispatch, requestData]);

    const columns: Array<Column> = [
        {
            label: "Select",
            value: "",
            render: (data: any, columnValue: string, index: number) => (
                <div className="in-pools-list__data">
                    <Radio key={index} value={data.id} selectedValue={selectedPool}
                           handleChange={selectPool} label=""/>
                </div>
            )
        },
        {
            label: "Pool id",
            value: "internalPoolId",
        },
        {
            label: "Pool Title",
            value: "title",
        },
        {
            label: "Available",
            value: "available",
        },
    ];

    const selectPool = (poolValue: number) => {
        const pool = data.filter((pool: Pool) => pool.id === poolValue)[0];
        setSelectedPool(pool);
    }

    const onPageChange = (pageNumber: number) => {
        const page = pageNumber !== 0 ? pageNumber - 1 : pageNumber;
        changeRequestData({...requestData, pageNumber: page})
    }

    const search = (searchTerm: string) => {
        changeRequestData({...requestData, searchTerm});
    }

    const renderTableCell = (row: any, column: any, index: number) => {
        return (
            <TableCell key={index}>
                {
                    column.render ? (
                        column.render(row, column.value)
                    ) : (
                        <span className="elipsis-200">
                            {row[column.value]}
                        </span>
                    )
                }
            </TableCell>
        )
    }

    return (
        <ErrorBoundary>
            <div className="add-pools-container">
                <div className="add-pools-header">
                    <Search search={search}/>
                </div>
                <Loader isLoading={status === STATUS_LOADING}>
                    <Table>
                        <TableHead>
                            {columns.map(({label}, index) => <TableHeadCell key={index} heading={label}/>)}
                        </TableHead>
                        <TableBody>
                            {
                                data.map((row: any, index: any) => (
                                    <TableRow key={index}>
                                        {
                                            columns.map((column, i) => (
                                                renderTableCell(row, column, i)
                                            ))
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                        <Pagination
                            colSpan={columns.length}
                            totalCount={totalCount}
                            showPerPage={8}
                            currentPage={requestData.pageNumber + 1}
                            onPageChange={onPageChange}
                        />
                    </Table>
                </Loader>
            </div>
        </ErrorBoundary>
    );
};

export default InPoolsAdd;
