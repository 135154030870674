import React, {useEffect, useState} from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {Column} from "../../../../interfaces/Column";
import TableCell from "../../../shared/Table/TableCell";
import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableBody from "../../../shared/Table/TableBody";
import TableRow from "../../../shared/Table/TableRow";
import {Winner} from "../../../../interfaces/Winner";
import Pagination from "../../../shared/Pagination/Pagination";
import {dateTimeInPast, formatISODate, formatPrizeExpirationDate, truncateText} from "../../../../util/util";
import Button from "../../../shared/Button/Button";
import {useAppDispatch, useAppSelector} from "../../../../data/hooks";
import {resendPrize} from "../../../../data/slices/resendPrizeSlice";
import Alert from "../../../shared/Alert/Alert";
import {ReactComponent as Eye} from "../../../../assets/icons/eye.svg";
import "./WinnerSearchTable.scss";
import {NO_WIN, POOL_ACTIVE} from "../../../../constants/constants";
import Modal from "../../../shared/Modal/Modal";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import TextArea from "../../../shared/TextArea/TextArea";
import {fetchWinnerPrizes} from "../../../../data/slices/winnerPrizes";
import {Prize} from "../../../../interfaces/Prize";
import {Option} from "../../../../interfaces/Option";

type Props = {
    data: Array<Winner>,
    isLoading: boolean,
    totalCount: number,
    onPageChange: Function,
    currentPage: number,
    campaignId: string | undefined,
    generateWin: Function,
    campaignTimeZoneOffset: number
}

const WinnerSearchTable: React.FC<Props> = (props: Props) => {
    const {data, isLoading, totalCount, onPageChange, currentPage, campaignId, generateWin, campaignTimeZoneOffset} = props;
    const dispatch = useAppDispatch();
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isCodeAlertOpen, setIsCodeAlertOpen] = useState(false);
    const [codeName, setCodeName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prizeOptions, updatePrizeOptions] = useState([]);
    const [reason, updateReason] = useState("");
    const [selectedPrize, updateSelectedPrize] = useState<Option | null>(null);
    const [winner, updateWinner] = useState<Winner | null>(null);
    const [visitorId, updateVisitorId] = useState<string | null>(null);
    const [resetSuccessMessage, setRessetSuccessMessage] = useState("Successfully sent email.");
    const prizes = useAppSelector((state: any) => state.winnerPrizes.data);
    const isResending = useAppSelector((state: any) => state.resendPrize.status === 'loading');
    const resendingPrizeId = useAppSelector((state: any) => state.resendPrize.data);
    const isLoadingPrizes = useAppSelector((state: any) => state.winnerPrizes.status === "loading");

    useEffect(() => {
        getVisitorId();
    }, []);

    useEffect(() => {
        const options = prizes.filter((prize: Prize) => !!prize.available).map((prize: Prize) => {
            return {
                id: prize.id,
                label: `${truncateText(prize.title, 20)} (Available ${prize.available})`,
                title: prize.title
            }
        });

        updateSelectedPrize(options[0]);
        updatePrizeOptions(options);
    }, [prizes]);


    const columns: Array<Column> = [
        {
            label: "Player email",
            value: "email",
        },
        {
            label: "Prize name",
            value: "prizeTitle",
            render: (data: any, columnValue: string) => (
                <span className="elipsis-275" title={data[columnValue]}>
                    {data[columnValue]}
                </span>
            )
        },
        {
            label: "Date and time",
            value: "playRegistrationDate",
            render: (data: any, columnValue: string) => (
                formatISODate(data[columnValue], campaignTimeZoneOffset)
            )
        },
        {
            label: "Prize expiration date",
            value: "prize_expiration_date",
            render: (data: any, columnValue: string) => (
                checkIfExpired(data, columnValue)
            )
        },
        {
            label: "Redeemed",
            value: "isRedeemed",
            render: (data: any, columnValue: string) => (
                data[columnValue] ? "YES" : "NO"
            )
        },
        {
            label: "Redeemed date and time",
            value: "dateRedeemed",
            render: (data: any, columnValue: string) => {
                if (data[columnValue]) {
                    return formatISODate(data[columnValue], campaignTimeZoneOffset);
                }
                return "";
            }
        },
        {
            label: "Prize code",
            value: "code",
            render: (data: any, columnValue: string) => {
                if (data[columnValue]) {
                    return (
                        <Eye
                            title="See code"
                            className="cursor-pointer m-auto"
                            onClick={() => setCode(data[columnValue])}
                        />
                    )
                }
                return "";
            }
        },
        {
            label: "",
            value: "newWin",
            render: (data: any) => {
                return data.prizeTitle !== NO_WIN ? (
                    <Button
                        key={data.uuid}
                        type="primary"
                        onClick={() => openModal(data.uuid)}
                        customClass="btn-table"
                    >
                        generate new win
                    </Button>
                ) : ""
            }
        },
        {
            label: "",
            value: "resendCode",
            render: (data: any) => {
                return renderResendCode(data);
            }
        }
    ];

    const openModal = (uuid: string) => {
        const winner = data.find((item) => item.uuid === uuid) ?? null;
        updateWinner(winner);
        setIsModalOpen(true);
        if (campaignId) {
            dispatch(fetchWinnerPrizes(campaignId))
        }
    }

    const renderResendCode = (data: any) => {
        const isPrizeExpired = dateTimeInPast(data.prize_expiration_date);
        if (
            data.poolStatus !== POOL_ACTIVE ||
            data.prizeTitle === NO_WIN ||
            (isPrizeExpired && !data.dateRedeemed)
        ) {
            return ""
        }

        return (
            <Button
                key={data.uuid}
                disabled={isResending}
                isLoading={resendingPrizeId === data.uuid}
                type="primary"
                onClick={() => sendEmail(data.uuid, !!data.emailsDisabled)}
                customClass="btn-table btn-table--resend"
            >{data.emailsDisabled ? "Reset Prize" : "Resend E-Mail"}</Button>
        )
    }

    const getVisitorId = () => {
        const fpPromise = FingerprintJS.load().then();

        void (async () => {
            // Get the visitor identifier when you need it.
            const fp = await fpPromise;
            const result = await fp.get();

            // This is the visitor identifier:
            updateVisitorId(result.visitorId);
        })();
    }

    const generateNewWin = () => {
        generateWin(
            winner?.email,
            getSelectedPrize()?.id,
            visitorId,
            reason,
            winner?.language || '',
            winner?.languageUUID || ''
        );

        setIsModalOpen(false);
        updateReason("");
    }

    const sendEmail = (uuid: string, emailsDisabled: boolean) => {
        dispatch(resendPrize({uuid, campaignId: campaignId || ''})).then(() => {
            setRessetSuccessMessage(emailsDisabled ? "Successfully reset prize." : "Successfully sent email.");
            setIsAlertOpen(true);
        });
    }

    const checkIfExpired = (data: any, columnValue: string) => {
        if (data[columnValue]) {
            const isInPast = dateTimeInPast(data[columnValue]);
            return <span className={isInPast ? "red" : ""}>
                {formatPrizeExpirationDate(data[columnValue], campaignTimeZoneOffset)}
                {isInPast && <br/>}
                {isInPast && "EXPIRED"}
            </span>
        }
        return ""
    }

    const renderTableCell = (row: any, column: any, index: number) => {
        return (
            <TableCell key={index}>
                {
                    column.render ? (
                        column.render(row, column.value)
                    ) : (
                        <span className="elipsis-180" title={row[column.value]}>
                            {row[column.value]}
                        </span>
                    )
                }
            </TableCell>
        )
    }

    const setCode = (code: string) => {
        setCodeName(code);
        setIsCodeAlertOpen(true);
    }

    const handlePrizeChange = (option: Option) => {
        updateSelectedPrize(option);
    }

    const getSelectedPrize = () => {
        return selectedPrize ? selectedPrize : prizeOptions[0]
    }

    const handleReasonChange = (e: any) => {
        updateReason(e.target.value);
    }

    return (
        <ErrorBoundary>
            <Loader isLoading={isLoading}>
                <Table className="no-image-table white-space-normal" wrapperClassName="winner-search-table-wrapper">
                    <TableHead>
                        {columns.map(({label}, index) => <TableHeadCell key={index} heading={label}/>)}
                    </TableHead>
                    <TableBody>
                        {
                            data.map((row, index) => (
                                <TableRow key={index}>
                                    {
                                        columns.map((column, i) => (
                                            renderTableCell(row, column, i)
                                        ))
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                    <Pagination
                        colSpan={columns.length}
                        totalCount={totalCount}
                        showPerPage={8}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                    />
                </Table>
            </Loader>

            <Modal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                title="Create new win"
                customClass="new-win-modal"
            >
                <Loader isLoading={isLoadingPrizes} height={300}>
                    {prizeOptions.length
                        ? (
                            <>
                                <div className="new-win-modal__input">
                                    <span className="new-win-modal__label">Code Type</span>
                                    <p>{winner?.email}</p>
                                </div>
                                <div className="new-win-modal__input">
                                    <span className="new-win-modal__label">Select prize</span>
                                    <Dropdown
                                        data={prizeOptions}
                                        selectedValue={getSelectedPrize()}
                                        handleChange={handlePrizeChange}
                                    />

                                </div>
                                <div className="new-win-modal__input">
                                    <span className="new-win-modal__label">Reason</span>
                                    <TextArea
                                        placeholder="Please describe the reason why new prize was issued"
                                        name="reason"
                                        onChange={handleReasonChange}
                                        value={reason}
                                    />
                                </div>
                                <div className="modal__footer">
                                    <div className="modal__footer--btns">
                                        <Button type="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                                        <Button type="primary" onClick={() => generateNewWin()}>Save</Button>
                                    </div>
                                </div>
                            </>
                        )
                        : (<div className="modal__no-prizes-available">No prizes available.</div>)
                    }
                </Loader>
            </Modal>

            <Alert
                isOpen={isAlertOpen}
                setIsOpen={setIsAlertOpen}
                title="Success"
                text={resetSuccessMessage}
                type="primary"
            />

            <Alert
                isOpen={isCodeAlertOpen}
                setIsOpen={setIsCodeAlertOpen}
                title="Code"
                text={codeName}
                type="primary"
            />
        </ErrorBoundary>
    );
};

export default WinnerSearchTable;
