import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {searchWinners} from "../api/winners";
import {Winner} from "../../interfaces/Winner";
import {STATUS_FAILED, STATUS_IDLE, STATUS_LOADING} from "../../constants/constants";
import { getCompanyTimeZoneOffset } from "../../util/util";

export interface WinnersState {
    data: Array<Winner>;
    dataCount: number;
    campaignTimeZoneOffset: number;
    status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: WinnersState = {
    data: [],
    status: STATUS_IDLE,
    dataCount: 0,
    campaignTimeZoneOffset: getCompanyTimeZoneOffset()
};

const winnerSlice = createSlice({
    name: "winners",
    initialState,
    reducers: {
        searchWinnersStarted: (state) => {
            state.status = STATUS_LOADING;
        },
        searchWinnersSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data.winners;
                state.dataCount = action.payload.data.dataCount;
                state.campaignTimeZoneOffset = action.payload.data.campaignTimeZoneOffset;
            } else {
                state.data = initialState.data;
                state.dataCount = initialState.dataCount;
            }

        },
        searchWinnersError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.code, action.payload.message);
        }
    },
});

const {searchWinnersStarted, searchWinnersSuccess, searchWinnersError} = winnerSlice.actions;

export const selectWinners = (state: RootState) => state.winners.data;
export const selectDataCount = (state: RootState) => state.winners.dataCount;
export const selectStatus = (state: RootState) => state.winners.status;

export default winnerSlice.reducer;

export const fetchWinners = (data: any) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(searchWinnersStarted());
            const result = await searchWinners(data);
            dispatch(searchWinnersSuccess(result.data));
        } catch (error) {
            dispatch(searchWinnersError(error))
        }
    }
}
