import {api} from "./api";
import {Prize} from "../../interfaces/Prize";

export const searchPrizes = (data: any) => {
    return api.post("prize-management/prizes", data)
        .then((result) => result)
}

export const importPrizes = (data: FormData) => {
    return api.post("prize-management/prizes/import", data)
        .then((result) => result);
}

export const getPrize = (id: number) => {
    return api.get(`prize-management/prizes/${id}`)
        .then((result) => result)
}

export const createPrize = (data: any) => {
    return api.post("prize-management/prizes/create", data)
        .then((result) => result)
}

export const putPrize = (data: Prize, id: number) => {
    return api.put(`prize-management/prize/${id}`, data)
        .then((result) => result);
}

export const deletePrize = (id: number) => {
    return api.delete(`prize-management/prizes/delete/${id}`)
        .then((result) => result);
}
