import React from "react";
import "./BarCode.scss";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Barcode from "react-barcode/lib/react-barcode";

type Props = {
    code: any
}

const BarCode: React.FC<Props> = ({code}: Props) => {

    const getCodeFormat = () => {
        switch (code.type) {
            case "EAN128":
                return "CODE128";
            case "EAN13":
                return "EAN13";
        }
    }

    return (
        <ErrorBoundary>
                <div className="barcode">
                    <Barcode value={code.name} format={getCodeFormat()}/>
                </div>
        </ErrorBoundary>
    );
};

export default BarCode;
