import React, {useState} from "react";
import "./BatchesTable.scss";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableBody from "../../../shared/Table/TableBody";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableRow from "../../../shared/Table/TableRow";
import TableCell from "../../../shared/Table/TableCell";
import {Column} from "../../../../interfaces/Column";

import {ReactComponent as Delete} from "../../../../assets/icons/delete.svg";
import {ReactComponent as Edit} from "../../../../assets/icons/edit.svg";
import {ReactComponent as ArrowDown} from "../../../../assets/icons/angle-down.svg";
import Button from "../../../shared/Button/Button";
import Modal from "../../../shared/Modal/Modal";
import BatchesEdit from "../BatchesEdit/BatchesEdit";
import {getDate} from "../../../../util/util";
import {useAppDispatch} from "../../../../data/hooks";
import {updateBatch} from "../../../../data/slices/batchSlice";
import {fetchBatchesByPrizeId} from "../../../../data/slices/batchesSlice";
import CodesList from "../CodesList/CodesList";
import { prizeCode } from "../../../../constants/constants";

type Props = {
    data: Array<any>,
    prizeId: number,
    isLoading: boolean,
    deleteBatch: Function
}

const BatchesTable: React.FC<Props> = ({data, prizeId, isLoading, deleteBatch}: Props) => {

    const initialBatchData = {
        id: 0,
        expiration: "",
        codeValidity: 0,
        prizeValidity: 0,
        codeType: "",
        batchType: prizeCode.INDIVIDUAL_CODE,
    }

    const columns: Array<Column> = [
        {
            label: "Batch no",
            value: "id",
        },
        {
            label: "Codes Amount",
            value: "codesAmount",
        },
        {
            label: "Redeemed",
            value: "redeemed"
        },
        {
            label: "Reserved",
            value: "reserved"
        },
        {
            label: "Avaliable",
            value: "available"
        },
        {
            label: "Code Validity",
            value: "codeValidity",
            render: (data: any, columnValue: string) => (
                data[columnValue] + " min"
            )
        },
        {
            label: "Batch Expiration",
            value: "expiration",
            render: (data: any, columnValue: string) => (
                getDate(data[columnValue].replace("T", " "))
            )
        },
        {
            label: "",
            value: "delete",
            render: (data: any) => (
                <Delete onClick={() => deleteBatch(data.id)}/>
            )
        },
        {
            label: "",
            value: "edit",
            render: (data: any) => (
                <Edit onClick={() => toggleEditModal(data)}/>
            )
        },
        {
            label: "",
            value: "expand",
            render: (data: any, columnValue: string, rowIndex: number) => (
                <ArrowDown className="expand-row" onClick={() => toggleExpandedRow(rowIndex)}/>
            )
        },
    ];

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenListModal, setIsOpenListModal] = useState(false);
    const [selectedBatchId, setSelectedBatchId] = useState(0);
    const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
    const [batchData, setBatchData] = useState(initialBatchData);
    const dispatch = useAppDispatch();

    const toggleEditModal = (data: any) => {
        setBatchData(data);
        setIsOpenEditModal(!isOpenEditModal);
    }

    const toggleExpandedRow = (rowIndex: number | null) => {
        const index = rowIndex === expandedRowIndex ? null : rowIndex;
        setExpandedRowIndex(index)
    }

    const save = () => {
        dispatch(updateBatch(batchData, batchData.id)).then(() => {
            setIsOpenEditModal(!isOpenEditModal);
            dispatch(fetchBatchesByPrizeId(prizeId));
        });
    }

    const renderTableCell = (row: any, column: any, index: number, rowIndex: number) => {
        return (
            <TableCell key={index}>
                {
                    column.render ? (
                        column.render(row, column.value, rowIndex)
                    ) : (
                        <span className="elipsis-200">
                            {row[column.value]}
                        </span>
                    )
                }
            </TableCell>
        )
    }

    const renderExpandedData = (row: any) => {
        return (
            <TableCell>
                <p onClick={() => toggleCodesListModal(row.id)}  className="codes-link">List of codes</p>
            </TableCell>
        )
    }

    const toggleCodesListModal = (batchId: number) => {
        setSelectedBatchId(batchId);
        setIsOpenListModal(!isOpenListModal);
    }

    return (
        <ErrorBoundary>
            <Loader isLoading={isLoading}>
                <Table className="no-image-table with-scroll">
                    <TableHead>
                        {columns.map(({label}, index) => <TableHeadCell key={index} heading={label}/>)}
                    </TableHead>
                    <TableBody>
                        {
                            data.length > 0 ? (
                                data.map((row, index) => (
                                    <TableRow key={index} expanded={expandedRowIndex === index}
                                              expandedData={renderExpandedData(row)}>
                                        {
                                            columns.map((column, i) => (
                                                renderTableCell(row, column, i, index)
                                            ))
                                        }
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <td className="no-data" colSpan={columns.length}>No data</td>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </Loader>

            <Modal
                isOpen={isOpenEditModal}
                setIsOpen={setIsOpenEditModal}
                title={`Edit batch id: ${batchData.id}`}
                customClass="codes-edit-modal"
            >
                <BatchesEdit data={batchData} onChange={setBatchData}></BatchesEdit>
                <div className="modal__footer">
                    <div className="modal__footer--btns">
                        <Button type="secondary" onClick={() => setIsOpenEditModal(!isOpenEditModal)}>Cancel</Button>
                        <Button type="primary" onClick={() => save()}>Save</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isOpenListModal}
                setIsOpen={setIsOpenListModal}
                title={`List of codes`}
                customClass="codes-list-modal"
            >
                <CodesList batchId={selectedBatchId}></CodesList>
                <div className="modal__footer">
                    <div className="modal__footer--btns">
                        <Button type="secondary" onClick={() => setIsOpenListModal(!isOpenListModal)}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        </ErrorBoundary>
    );
};

export default BatchesTable;
