import React, {useEffect, useState} from "react";

import "./Batches.scss";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import Button from "../../../shared/Button/Button";
import BatchesTable from "../BatchesTable/BatchesTable";

import BatchesUpload from "../BatchesUpload/BatchesUpload";
import Modal from "../../../shared/Modal/Modal";
import {useAppDispatch, useAppSelector} from "../../../../data/hooks";
import {addBatch, emptyBatch, removeBatch, selectDuplicateCodesInfo, selectHasDuplicateCodes} from "../../../../data/slices/batchSlice";
import {emptyBatches, fetchBatchesByPrizeId} from "../../../../data/slices/batchesSlice";
import Confirm from "../../../shared/Confirm/Confirm";
import {DEFAULT_COMMON_CODE_BATCH, prizeCode, STATUS_LOADING} from "../../../../constants/constants";
import { Batch, CommonCodeBatch } from "../../../../interfaces/Batch";
import CommonCodeBatchComponent from "../CommonCodeBatch/CommonCodeBatch";

type Props = {
    data: any;
    onCodeTypeChange: Function;
    onCommonCodeBatchChangeCb: (batch: CommonCodeBatch | null) => void;
}

const Batches: React.FC<Props> = ({ data, onCodeTypeChange, onCommonCodeBatchChangeCb }: Props) => {

    const options = [
        {
            id: prizeCode.NO_CODE,
            label: "No code"
        },
        {
            id: prizeCode.INDIVIDUAL_CODE,
            label: "Individual code"
        },
        {
            id: prizeCode.COMMON_CODE,
            label: "Common code",
        }
    ];

    const getOneYearFromNow = () => {
        const date = new Date();
        return new Date(date.setFullYear(date.getFullYear() + 1));
    }

    const initialBatchData = {
        prizeId: data.id,
        filename: "",
        expiration: getOneYearFromNow(),
        codeValidity: "15",
        codeType: "EAN128"
    }

    const errorsInitialState = {
        "filename": false,
        "expiration": false,
        "codeValidity": false,
        "codeType": false
    }

    const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [batchData, setBatchData] = useState(initialBatchData);
    const [deletingBatch, setDeletingBatch] = useState(0);
    const [codeTypeOption, setCodeTypeOption] = useState(data.codeType);
    const [commonCodeBatch, setCommonCodeBatch] = useState<CommonCodeBatch>(DEFAULT_COMMON_CODE_BATCH);
    const [errors, setErrors] = useState(errorsInitialState);
    const [isDeletingDuplicateCodesBatch, setIsDeletingDuplicateCodesBatch] = useState(false);
    const batches = useAppSelector((state: any) => state.batches.data.filter((b: Batch) => b.batchType === codeTypeOption));
    const batchesStatus = useAppSelector((state: any) => state.batches.status);
    const status = useAppSelector((state: any) => state.batch.status);
    const apiErrors = useAppSelector((state: any) => codeTypeOption === prizeCode.INDIVIDUAL_CODE
        ? state.batch.errors
        : state.prize.errors
    );
    const hasDuplicateCodes = useAppSelector(selectHasDuplicateCodes);
    const duplicateCodes = useAppSelector(selectDuplicateCodesInfo);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (data.id) {
            dispatch(fetchBatchesByPrizeId(data.id));
        }
    }, [dispatch, data.id]);

    useEffect(() => {
        if (batches && batches.length === 1 && batches[0].batchType === prizeCode.COMMON_CODE) {
            setCommonCodeBatch((batch) => ({
                ...batch,
                id: +batches[0].id,
                expiration: batches[0].expiration,
                codeValidity: +batches[0].codeValidity,
                codeType: batches[0].codeType,
                commonCode: batches[0].commonCode,
                codesAmount: batches[0].codesAmount
            }));
        }
    }, [batches]);

    useEffect(() => {
        onCommonCodeBatchChangeCb(codeTypeOption === prizeCode.COMMON_CODE ? commonCodeBatch : null);
    }, [onCommonCodeBatchChangeCb, codeTypeOption, commonCodeBatch]);

    const closeUploadModal = () => {
        setBatchData(initialBatchData);
        setIsOpenUploadModal(!isOpenUploadModal);
    }

    const validate = () => {
        const errors = {
            "filename": batchData.filename.length === 0,
            "expiration": !batchData.expiration,
            // @ts-ignore
            "codeValidity": batchData.codeValidity === 0,
            "codeType": batchData.codeType.length === 0
        };
        setErrors(errors);
        return Object.values(errors).every(
            value => !value
        );
    }

    const saveBatch = () => {
        if (!validate()) {
            return;
        }
        const formData = new FormData();
        formData.append("prizeId", data.id);
        formData.append("filename", batchData.filename);
        formData.append("expiration", batchData.expiration.toString());
        formData.append("codeValidity", batchData.codeValidity);
        formData.append("codeType", batchData.codeType);
        formData.append("batchType", prizeCode.INDIVIDUAL_CODE);
        dispatch(addBatch(formData)).then(() => {
            closeUploadModal()
            dispatch(fetchBatchesByPrizeId(data.id));
        });
    }

    const handleCodeTypeChange = (value: any) => {
        setCodeTypeOption(value.id);
        onCodeTypeChange(value.id);
        if (data.id) {
            // const type = value.id === prizeCode.INDIVIDUAL_CODE ? "" : value.id;
            const type = "";
            dispatch(fetchBatchesByPrizeId(data.id, type));
        } else {
            dispatch(emptyBatches());
        }
    }

    const getSelectedCodeType = () => {
        return options.filter((option) => option.id === codeTypeOption)[0] ?? options[1];
    }

    const deleteAlert = (batchId: number) => {
        setDeletingBatch(batchId);
        setIsAlertOpen(true);
    }

    const deleteBatch = () => {
        dispatch(removeBatch(deletingBatch)).then(() => {
            setIsAlertOpen(false);
            dispatch(fetchBatchesByPrizeId(data.id));
        });
    }

    const onCommonCodeBatchChange = (data: Partial<CommonCodeBatch>) => {
        setCommonCodeBatch((batch) => ({
            ...batch,
            ...data
        }));
    };

    const onDuplicateCodesDialogClose = () => {
        dispatch(emptyBatch());
    };

    const deleteDuplicateCodesBatch = () => {
        if (!isDeletingDuplicateCodesBatch && duplicateCodes && hasDuplicateCodes) {
            setIsDeletingDuplicateCodesBatch(true);

            dispatch(removeBatch((duplicateCodes).batchId)).then(() => {
                setIsDeletingDuplicateCodesBatch(false);
                dispatch(fetchBatchesByPrizeId(data.id));
            });
        }
    };

    return (
        <div className="codes">
            <div className="codes__header">
                <div className="codes__header--dropdown">
                    <Dropdown
                        data={options}
                        selectedValue={getSelectedCodeType()}
                        handleChange={handleCodeTypeChange}
                    />
                </div>

                {codeTypeOption === prizeCode.INDIVIDUAL_CODE ? (
                    <Button type="primary" onClick={() => setIsOpenUploadModal(!isOpenUploadModal)} disabled={!batchData.prizeId}>
                        Upload batch
                    </Button>
                ) : ("")}
            </div>

            <div className="codes__body">
                {codeTypeOption === prizeCode.INDIVIDUAL_CODE ? (
                    <BatchesTable
                        data={batches}
                        prizeId={data.id}
                        isLoading={batchesStatus === STATUS_LOADING}
                        deleteBatch={deleteAlert}
                    />
                ) : ("")}

                {apiErrors?.UNIQUE_CODES_MIN_LENGTH && (<span className="error-message">None of the uploaded codes are unique.</span>)}

                {codeTypeOption === prizeCode.COMMON_CODE ? (
                    <CommonCodeBatchComponent batch={commonCodeBatch} onDataChange={onCommonCodeBatchChange} />
                ) : ("")}

                {apiErrors?.UNIQUE_CODE && (<span className="error-message">Code already exists.</span>)}
            </div>

            <Modal
                isOpen={isOpenUploadModal}
                setIsOpen={setIsOpenUploadModal}
                title="Upload batch"
            >
                <BatchesUpload onChange={setBatchData} data={batchData} errors={errors}></BatchesUpload>
                <div className="modal__footer">
                    <div className="modal__footer--btns">
                        <Button type="secondary" onClick={() => closeUploadModal()}>Cancel</Button>
                        <Button type="primary" onClick={() => saveBatch()} isLoading={status === STATUS_LOADING}>Save</Button>
                    </div>
                </div>
            </Modal>

            <Confirm
                isOpen={isAlertOpen}
                setIsOpen={setIsAlertOpen}
                title="Remove batch"
                text="Are you sure you want to remove this batch?"
                buttonText="Yes"
                type="danger"
                action={deleteBatch}
            />

            <Modal
                isOpen={hasDuplicateCodes}
                setIsOpen={onDuplicateCodesDialogClose}
                title="Duplicate codes found"
                customClass="duplicate-codes__dialog"
            >
                <div className="duplicate-codes__message">{duplicateCodes?.savedCodesCount} unique codes out of {duplicateCodes?.sentCodesCount} saved.</div>

                <div className="modal__footer">
                    <div className="modal__footer--btns">
                        <Button type="secondary" onClick={() => deleteDuplicateCodesBatch()} disabled={isDeletingDuplicateCodesBatch} isLoading={isDeletingDuplicateCodesBatch}>Undo</Button>
                        <Button type="primary" onClick={() => onDuplicateCodesDialogClose()} disabled={isDeletingDuplicateCodesBatch}>Finish</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Batches;
