import {Tab} from "@headlessui/react";
import React, {Fragment} from "react";

import "./Tabs.scss";

type Props = {
    tabs: Array<{
        title: string,
        component: JSX.Element,
        disabled?: boolean,
        tooltip?: string | undefined
    }>
    type: "big" | "small",
    selectedIndex?: number
}

const Tabs = ({tabs, type, selectedIndex}: Props) => {
    return (
        <Tab.Group as="div" className={`tabs ${type}`} selectedIndex={selectedIndex ? selectedIndex : 0}>
            <Tab.List as="div" className="tabs__list">
                {
                    tabs.map((tab, index) => (

                        <Tab key={index} as={Fragment}>
                            {({ selected }) => (
                                <button
                                    className={`tabs__btn ${selected ? "active" : ""}`}
                                    disabled={tab.disabled}
                                    title={tab.tooltip}
                                >
                                    { tab.title }
                                </button>
                            )}
                        </Tab>

                    ))
                }
            </Tab.List>
            <Tab.Panels>
                {
                    tabs.map((tab, index) => (
                        <Tab.Panel key={index}>
                            {tab.component}
                        </Tab.Panel>
                    ))
                }
            </Tab.Panels>
        </Tab.Group>
    );
};

export default Tabs;
