import React, {ChangeEventHandler} from "react";

import "./Input.scss";

type Props = {
    placeholder?: string,
    name: string,
    type: string,
    onChange?: ChangeEventHandler<HTMLInputElement>,
    value?: string | number
    readOnly?: boolean,
    min?: string | number;
    max?: string | number;
    errors?: boolean;
}
const Input = ({placeholder, name, type, onChange, value, readOnly, min, max, errors}: Props) => {
    return (
        <input className={ `input ${errors ? "error" : ""}` }
               placeholder={placeholder}
               name={name}
               type={type}
               onChange={onChange}
               value={value}
               readOnly={!!readOnly}
               min={min}
               max={max}
               aria-label="input"
        />
    );
};

export default Input;
