import React, {useEffect, useState} from "react";
import Search from "../../components/shared/Search/Search";

import "./WinnerSearch.scss";
import WinnerSearchTable from "../../components/features/winner-search/WInnerSearchTable/WinnerSearchTable";
import {useAppDispatch, useAppSelector} from "../../data/hooks";
import {fetchWinners} from "../../data/slices/winnersSlice";
import {useParams} from "react-router-dom";
import {fetchPoolsByCampaignid} from "../../data/slices/poolsSlice";
import {Winner} from "../../interfaces/Winner";
import {Pool} from "../../interfaces/Pool";
import {STATUS_LOADING} from "../../constants/constants";
import {Win} from "../../interfaces/Win";
import {createWin} from "../../data/slices/generateWinSlice";
import { RootState } from "../../data/store";

const WinnerSearch = () => {
        const {campaignId} = useParams();
        const initialRequestData = {
            searchTerm: "",
            campaignId,
            perPage: 8,
            pageNumber: 0,
            sortBy: "play_registration_date",
            order: "DESC"
        }
        const [requestData, changeRequestData] = useState(initialRequestData);
        const winners = useAppSelector((state: any) => state.winners.data);
        const pools = useAppSelector((state: any) => state.pools.data);
        const totalCount = useAppSelector((state: any) => state.winners.dataCount);
        const status = useAppSelector((state: any) => state.winners.status);
        const createWinStatus = useAppSelector((state: any) => state.win.status);
        const campaignTimeZoneOffset = useAppSelector((state: RootState) => state.winners.campaignTimeZoneOffset);
        const dispatch = useAppDispatch();

        useEffect(() => {
            dispatch(fetchWinners(requestData)).then(() => {
                if (campaignId) {
                    dispatch(fetchPoolsByCampaignid(campaignId));
                }
            })
        }, [campaignId, dispatch, requestData]);

        const prepareData = () => {
            if (winners && pools) {
                return winners.map((winner: Winner) => {
                    if (winner.poolId) {
                        const winnerPools = pools.filter((pool: Pool) => winner.poolId === pool.id);
                        return {
                            ...winner,
                            poolStatus: winnerPools.length ? winnerPools[0].status : 0
                        };
                    }
                    return {...winner, poolStatus: 0};
                });
            }

            return winners;
        }

        const search = (searchTerm: string) => {
            changeRequestData({...requestData, searchTerm});
        }

        const onPageChange = (pageNumber: number) => {
            const page = pageNumber !== 0 ? pageNumber - 1 : pageNumber;
            changeRequestData({...requestData, pageNumber: page})
        }

        const generateNewWin = (email: string, prizeId: number, visitorId: string, message: string, language: string, languageUUID: string) => {
            if (campaignId) {
                const win: Win = {
                    firstName: "",
                    lastName: "",
                    email,
                    campaignId,
                    prizeId,
                    visitorId,
                    language,
                    languageUUID,
                    message
                }

                dispatch(createWin(win)).then(() => {
                    dispatch(fetchWinners(requestData)).then(() => {
                        dispatch(fetchPoolsByCampaignid(campaignId));
                    })
                });
            }
        }

        return (
            <div className="container-lg">

                <div className="winner-search">
                    <h2>Enter an e-mail below to check if they won something</h2>
                    <div className="winner-search__header">
                        <Search search={search} placeholder="Enter an email"/>
                    </div>

                    <div className="winner-search__body">
                        <WinnerSearchTable
                            data={prepareData()}
                            isLoading={status === STATUS_LOADING || createWinStatus === STATUS_LOADING}
                            totalCount={totalCount}
                            onPageChange={onPageChange}
                            currentPage={requestData.pageNumber + 1}
                            campaignId={campaignId}
                            generateWin={generateNewWin}
                            campaignTimeZoneOffset={campaignTimeZoneOffset}
                        />
                    </div>
                </div>

            </div>
        );
    }
;

export default WinnerSearch;
