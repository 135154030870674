import React from 'react';
import DatePicker from "react-datepicker";

import './CommonCodeBatch.scss';

import { barcodeType } from '../../../../constants/constants';
import { CommonCodeBatch } from '../../../../interfaces/Batch';
import Dropdown from '../../../shared/Dropdown/Dropdown';
import Input from '../../../shared/Input/Input';
import { ReactComponent as Calendar } from "../../../../assets/icons/calendar.svg";

type BarcodeType = typeof barcodeType[keyof typeof barcodeType];
type DataChangedCb = (data: Partial<CommonCodeBatch>) => void;

interface CommonCodeBatchComponentProps {
    batch: CommonCodeBatch;
    onDataChange: DataChangedCb
}

const formLabels = {
    BARCODE_TYPE: 'Code Type',
    CODES_AMOUNT: 'Codes Amount',
    EXPIRATION: 'Code Expiration',
    VALIDITY: 'Code Validity'
};

const barcodeTypeOptions = (Object.keys(barcodeType) as Array<keyof typeof barcodeType>).map((type) => ({
    id: barcodeType[type],
    label: type as string
}));

const onBarcodeTypeChange: (type: BarcodeType, onDataChange: DataChangedCb) => void = (type, onDataChange) => {
    onDataChange({ codeType: type });
};

const onCommonCodeChange: (commonCode: string, onDataChange: DataChangedCb) => void = (commonCode, onDataChange) => {
    onDataChange({ commonCode });
};

const onCodesAmountChange: (amount: number, onDataChange: DataChangedCb) => void = (amount, onDataChange) => {
    onDataChange({ codesAmount: amount });
};

const onExpirationDateChange: (date: Date | null, onDataChange: DataChangedCb) => void = (date, onDataChange) => {
    if (date) {
        onDataChange({ expiration: date.toISOString() });
    }
};

const onCodeValidityChange: (validity: number, onDataChange: DataChangedCb) => void = (validity, onDataChange) => {
    if (Number.isInteger(validity) && validity > 0) {
        onDataChange({ codeValidity: validity });
    }
};

const CommonCodeBatchComponent: React.FC<CommonCodeBatchComponentProps> = ({ batch, onDataChange }) => {
    return (
        <form className="common-code-batch" onSubmit={(e) => e.preventDefault()}>
            <div className="common-code-batch__row">
                <div className="common-code-batch__form-control common-code-batch__form-control--barcode">
                    <Input
                        name="codeValidity"
                        type="string"
                        placeholder="Barcode"
                        value={batch.commonCode as string}
                        onChange={({ target: { value } }) => onCommonCodeChange(value, onDataChange)}
                    />
                </div>
                <div className="common-code-batch__form-control common-code-batch__form-control--codes-amount">
                    <label className="form-control__label">{formLabels.CODES_AMOUNT}</label>
                    <Input
                        name="codesAmount"
                        type="number"
                        min={1}
                        max={100000}
                        value={batch.codesAmount}
                        onChange={({ target: { value } }) => onCodesAmountChange(+value, onDataChange)}
                    />
                </div>
                <div className="common-code-batch__form-control common-code-batch__form-control--barcode-type">
                    <label className="form-control__label">{formLabels.BARCODE_TYPE}</label>
                    <Dropdown
                        data={barcodeTypeOptions}
                        selectedValue={barcodeTypeOptions.find((o) => o.id === batch.codeType) as { id: string; label: string }}
                        handleChange={(option) => onBarcodeTypeChange(option.id as BarcodeType, onDataChange)}
                    />
                </div>
            </div>
            <div className="common-code-batch__row">
                <div className="common-code-batch__form-control common-code-batch__form-control--expiration">
                    <label className="form-control__label">{formLabels.EXPIRATION}</label>
                    <Calendar />
                    <DatePicker
                        selected={new Date(batch.expiration)}
                        onChange={(date) => onExpirationDateChange(date, onDataChange)}
                    />
                </div>
                <div className="common-code-batch__form-control common-code-batch__form-control--validity">
                    <label className="form-control__label">{formLabels.VALIDITY}</label>
                    <Input
                        name="codesAmount"
                        type="number"
                        min={1}
                        value={batch.codeValidity}
                        onChange={({ target: { value } }) => onCodeValidityChange(+value, onDataChange)}
                    />
                </div>
            </div>
        </form>
    );
};

export default CommonCodeBatchComponent;
