import React from "react";
import {Menu} from "@headlessui/react";

import "./Navbar.scss";
import logo from "../../../assets/images/brame-logo.png";
import {ReactComponent as UserIcon} from "../../../assets/icons/user.svg";
import AuthService from "../../../auth/AuthService";

const Navbar = () => {
    return (
        <nav>
            <div className="navbar">
                <a href="/" className="navbar__logo">
                    <img src={logo} alt="Brame logo"/>
                </a>
                <ul className="navbar__right">
                    <li><a className="link-item" href="/dashboard">Dashboard</a></li>
                    <li><a className="link-item" href="/users">Users</a></li>
                    <li><a className="link-item" href="/campaigns">Campaigns</a></li>
                    <li><a className="link-item" href="/prize-management">Prize Management</a></li>
                    <li>
                        <Menu as="div" className="navbar__dropdown">
                            <Menu.Button as="div" className="navbar__dropdown--link">
                                Support
                            </Menu.Button>
                            <Menu.Items as="div" className="navbar__dropdown--menu">
                                <Menu.Item as="div" className="navbar__dropdown--menu-item">
                                    <a href="/support/contact-us">Contact Us</a>
                                </Menu.Item>
                                <Menu.Item as="div" className="navbar__dropdown--menu-item">
                                    <a href="/support/user-guides">Support Page</a>
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                    </li>
                    <li>
                        <Menu as="div" className="navbar__dropdown">
                            <Menu.Button as="div" className="navbar__dropdown--btn">
                                <UserIcon/>
                            </Menu.Button>
                            <Menu.Items as="div" className="navbar__dropdown--menu">
                                <Menu.Item as="div" className="navbar__dropdown--menu-item">
                                    <a href="/account">My account</a>
                                </Menu.Item>
                                <Menu.Item as="div" className="navbar__dropdown--menu-item"
                                           onClick={() => AuthService.logout()}>
                                    Logout
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
