import React from "react";
import "./Table.scss";

type Props = {
    children: React.ReactNode,
    className?: string,
    wrapperClassName?: string
}

const Table: React.FC<Props> = ({children, className, wrapperClassName}: Props) => {
    return (
        <div className={`table-wrapper ${wrapperClassName ? wrapperClassName : ""}`}>
            <table className={`table ${className ? className : ""}`}>
                {children}
            </table>
        </div>
    );
}

export default Table;
