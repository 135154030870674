import {Pool} from "./Pool";

export interface Prize {
    id: number | null,
    internalPrizeId: string,
    updatedAt: string,
    createdAt: string
    title: string,
    description: string,
    gameImage: string
    resultsImage: string | null,
    legalNotes: string,
    labels: Array<string>,
    active: number,
    amount: number,
    available: number,
    daysValid: number,
    sentOut: number,
    redeemed: number,
    pools: Array<Pool>
    companyId: string
}

export class PrizeFactory {
    static getInitialData(): Prize {
        return {
            id: null,
            internalPrizeId: "",
            updatedAt: "",
            createdAt: "",
            title: "",
            description: "",
            gameImage: "",
            resultsImage: "",
            legalNotes: "",
            labels: [""],
            active: 0,
            amount: 0,
            available: 0,
            daysValid: 6,
            sentOut: 0,
            redeemed: 0,
            pools: [],
            companyId: localStorage.getItem("companyId") ?? ""
        }
    }
}
