import React from "react";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableBody from "../../../shared/Table/TableBody";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableRow from "../../../shared/Table/TableRow";
import TableCell from "../../../shared/Table/TableCell";
import Pagination from "../../../shared/Pagination/Pagination";
import {Column} from "../../../../interfaces/Column";
import {ReactComponent as Edit} from "../../../../assets/icons/edit.svg";
import {ReactComponent as Delete} from "../../../../assets/icons/delete.svg";
import {ReactComponent as Copy} from "../../../../assets/icons/copy.svg";
import {POOL_ACTIVE, POOL_DRAFT, POOL_INACTIVE} from "../../../../constants/constants";

type Props = {
    data: Array<any>,
    totalCount: number,
    isLoading: boolean,
    onPageChange: Function,
    currentPage: number,
    edit: Function,
    onDelete: (id: number) => void,
    onCopyClick: Function
}

const PrizePoolsTable: React.FC<Props> = (props: Props) => {
    const { data, totalCount, isLoading, onPageChange, currentPage, edit, onDelete, onCopyClick } = props;

    const columns: Array<Column> = [
        {
            label: "Status",
            value: "status",
            render: (data: any, columnValue: string) => (
                <div className={`activity ${checkStatus(data[columnValue])}`}
                     title={checkStatus(data[columnValue])}></div>
            )
        },
        {
            label: "ID",
            value: "internalPoolId",
        },
        {
            label: "Image",
            value: "image",
            render: (data: any, columnValue: string) => (
                data[columnValue] ? (
                    <img src={data[columnValue]} alt="Pool"/>
                ) : (
                    <img src={require('./../../../../assets/images/white.png')} alt="Pool"/>
                )
            )
        },
        {
            label: "Title",
            value: "title"
        },
        {
            label: "Description",
            value: "description"
        },
        {
            label: "Prizes",
            value: "prizes",
            render: (data, columnValue: string) => data[columnValue].length
        },
        {
            label: "Amount",
            value: "amount"
        },
        {
            label: "Sent Out / Redeemed",
            value: "sentOut",
            render: (data: any) => (
                <span>{data.sentOut ?? 0}/{data.redeemed ?? 0}</span>
            )
        },
        {
            label: "Avaliable",
            value: "available"
        },
        // {
        //     label: "Campaigns",
        //     value: "campaigns"
        // },
        {
            label: "",
            value: "delete",
            render: (data) => {
                return !data.sentOut && !data.redeemed && data.status !== POOL_ACTIVE
                    ? (<Delete onClick={() => onDelete(data.id)} />)
                    : <></>;
            }
        },
        {
            label: "",
            value: "edit",
            render: (data, columnValue) => (
                <Edit onClick={() => edit(data.id)}/>
            )
        },
        {
            label: "",
            value: "copy",
            render: (data, columnValue) => (
                <Copy className="cursor-pointer" onClick={() => onCopyClick(data.id)} />
            )
        }
    ];

    const checkStatus = (status: number) => {
        switch (status) {
            case POOL_INACTIVE:
                return "inactive";
            case POOL_ACTIVE:
                return "active";
            case POOL_DRAFT:
                return "draft";
        }
    }


    const renderTableCell = (row: any, column: any, index: number) => {
        return (
            <TableCell key={index}>
                {
                    column.render ? (
                        column.render(row, column.value)
                    ) : (
                        <span className="elipsis-200">
                            {row[column.value]}
                        </span>
                    )
                }
            </TableCell>
        )
    }

    return (
        <ErrorBoundary>
            <Loader isLoading={isLoading}>
                <Table>
                    <TableHead>
                        {columns.map(({label}, index) => <TableHeadCell key={index} heading={label}/>)}
                    </TableHead>
                    <TableBody>
                        {
                            data.length > 0 ? (
                                data.map((row, index) => (
                                        <TableRow key={index}>
                                            {
                                                columns.map((column, i) => (
                                                    renderTableCell(row, column, i)
                                                ))
                                            }
                                        </TableRow>
                                    )
                                )
                            ) : (
                                <TableRow>
                                    <td className="no-data" colSpan={columns.length}>No data</td>
                                </TableRow>
                            )
                        }
                    </TableBody>
                    <Pagination
                        colSpan={columns.length}
                        totalCount={totalCount}
                        showPerPage={8}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                    />
                </Table>
            </Loader>
        </ErrorBoundary>
    );
};

export default PrizePoolsTable;
