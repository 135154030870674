import React, {useEffect, useState} from "react";

import "./PrizePools.scss";
import Search from "../../../shared/Search/Search";
import Button from "../../../shared/Button/Button";
import PrizePoolsTable from "../PrizePoolsTable/PrizePoolsTable";
import Modal from "../../../shared/Modal/Modal";
import Tabs from "../../../shared/Tabs/Tabs";
import PoolDetails from "../PoolDetails/PoolDetails";
import InsidePool from "../InsidePool/InsidePool";
import InCampaign from "../InCampaign/InCampaign";
import LimitsAndProbability from "../LimitsAndProbability/LimitsAndProbability";
import {useAppDispatch, useAppSelector} from "../../../../data/hooks";
import {fetchPools, deletePool as deletePoolAction} from "../../../../data/slices/poolsSlice";
import {copyPool as copyPoolAction} from "../../../../data/slices/copySlice";
import {SearchPools} from "../../../../interfaces/SearchPools";
import {addPool, updatePool} from "../../../../data/slices/poolSlice";
import {Pool, PoolFactory} from "../../../../interfaces/Pool";
import {METHOD_EDIT, METHOD_ADD, STATUS_LOADING, PROBABILITY_DIFFERENT} from "../../../../constants/constants";
import {emptyCampaign} from "../../../../data/slices/campaignSlice";
import Confirm from "../../../shared/Confirm/Confirm";
import {RootState} from "../../../../data/store";
import Switch from "../../../shared/Switch/Switch";

const initialRequestData: SearchPools = {
    companyId: localStorage.getItem("companyId") ?? "",
    perPage: 8,
    pageNumber: 0,
    sortBy: "createdAt",
    order: "DESC",
    searchTerm: ""
}

const initialPoolData: Pool = PoolFactory.getInitialData();

const errorsInitialState = {
    title: false,
    durationInDays: false,
    winsPerPlay: false,
    numberPerPlay: false,
}

const copyPoolInitialState = {
    poolId: 0,
    copyCodes: false
};

const PrizePools = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
    const [copyPoolPayload, setCopyPoolPayload] = useState<{ poolId: number; copyCodes?: boolean }>(copyPoolInitialState);
    const [requestData, changeRequestData] = useState(initialRequestData);
    const [pool, changePoolData] = useState(initialPoolData);
    const [method, changeMethod] = useState(METHOD_ADD);
    const [errors, setErrors] = useState(errorsInitialState);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [deletingPoolId, setDeletingPoolId] = useState(0);
    const pools = useAppSelector((state: any) => state.pools.data);
    const totalCount = useAppSelector((state: any) => state.pools.dataCount);
    const status = useAppSelector((state: any) => state.pools.status);
    const addedPool = useAppSelector((state: any) => state.pool.data);
    const poolStatus = useAppSelector((state: any) => state.pool.status);
    const isCopyInProgress = useAppSelector((state: RootState) => state.copy.pool.status === STATUS_LOADING);
    const dispatch = useAppDispatch();

    const isTabDisabled = () => {
        const params = ["title", "durationInDays"];
        const invalid = [];
        Object.keys(pool).forEach((param: string) => {
            // @ts-ignore
            if (params.includes(param) && !pool[param]) {
                invalid.push(param);
            }
        })

        return invalid.length > 0;
    }

    const dataChange = (data: any) => {
        changePoolData(data);
        setErrors(errorsInitialState);
    }

    const tabs = [
        {
            title: "Pool details",
            component: <PoolDetails handleDataChange={dataChange} pool={pool} errors={errors}/>
        },
        {
            title: "Prize inside pool",
            component: <InsidePool handleDataChange={changePoolData} pool={pool} requestData={requestData}/>,
            disabled: isTabDisabled(),
            tooltip: isTabDisabled() ? "Please populate the details first." : ""
        },
        {
            title: "In campaign",
            component: <InCampaign handleDataChange={changePoolData} pool={pool}/>,
            disabled: isTabDisabled(),
            tooltip: isTabDisabled() ? "Please populate the details first." : ""
        },
        {
            title: "Limits & probability",
            component: <LimitsAndProbability handleDataChange={changePoolData} pool={pool} winsPerPlayError={errors.winsPerPlay} numberPerPlayError={errors.numberPerPlay}/>,
            disabled: isTabDisabled(),
            tooltip: isTabDisabled() ? "Please populate the details first." : ""
        }
    ];


    useEffect(() => {
        if (addedPool.id) {
            changePoolData(addedPool);
        }
        if (!requestData) return;
        dispatch(fetchPools(requestData));
    }, [addedPool, dispatch, requestData]);

    const toggle = (method: string) => {
        if (method === METHOD_ADD) {
            changePoolData(initialPoolData);
            setErrors(errorsInitialState);
            changeMethod(method);
            setIsOpen(!isOpen);
        } else {
            dispatch(emptyCampaign());
            changeMethod(method);
            setIsOpen(!isOpen);
        }
    }

    const validate = () => {
        const errors = {
            title: pool.title.length === 0,
            durationInDays: pool.durationInDays === 0,
            winsPerPlay: validateWinsPerPlay(),
            numberPerPlay: pool.perPlay === 0,
        };
        setErrors(errors);
        return Object.values(errors).every(
            value => !value
        );
    }

    const validateWinsPerPlay = () => {
        if (pool.winProbabilityType === PROBABILITY_DIFFERENT) {
            const checks = pool.winProbabilityPerDay.map((item) => {
                return item.wins > item.perPlay;
            });
            if (checks.includes(true)) {
                return true;
            }

            return false;
        }

        return pool.wins > pool.perPlay
    }

    const save = () => {
        if (!validate()) {
            if (errors.winsPerPlay) {
                if (selectedIndex !== 3) {
                    setSelectedIndex(3);
                }
            }
            return;
        }
        if (method === METHOD_ADD) {
            dispatch(addPool(pool)).then(() => {
                dispatch(fetchPools(requestData));
            });
        } else {
            dispatch(updatePool(pool)).then(() => {
                dispatch(fetchPools(requestData));
            });
        }

        toggle(METHOD_ADD);
    }

    const onPageChange = (pageNumber: number) => {
        const page = pageNumber !== 0 ? pageNumber - 1 : pageNumber;
        changeRequestData({...requestData, pageNumber: page})
    }

    const search = (searchTerm: string) => {
        changeRequestData({...requestData, searchTerm});
    }

    const edit = (poolId: number) => {
        changeMethod(METHOD_EDIT);
        setIsOpen(true);
        const pool = pools.filter((pool: Pool) => pool.id === poolId)[0];
        changePoolData(pool);
    }

    const showDeleteAlter = (poolId: number) => {
        setDeletingPoolId(poolId);
        setIsDeleteAlertOpen(true);
    };

    const deletePool = () => {
        if (!deletingPoolId) {
            return;
        }

        dispatch(deletePoolAction(deletingPoolId))
            .then(() => {
                setIsDeleteAlertOpen(false);

                const poolsLength = pools.length - 1;

                if (poolsLength < 1 && requestData.pageNumber > 0) {
                    changeRequestData({  ...requestData, pageNumber: requestData.pageNumber - 1  });
                } else {
                    dispatch(fetchPools(requestData));
                }
            });
    };

    const showCopyModal = (poolId: number) => {
        setCopyPoolPayload((payload) => ({ ...payload, poolId }));
        setIsCopyModalOpen(true);
    };

    const onCopySuccess = () => {
        setIsCopyModalOpen(false);
        dispatch(fetchPools(requestData));
    };

    const copyPool = () => {
        const { poolId, ...payload } = copyPoolPayload;

        dispatch(copyPoolAction(poolId, payload))
            .then(onCopySuccess);
    };

    return (
        <div className="pools">
            <div className="pools__header">
                <div className="pools__header--left">
                    <Search search={search}/>
                </div>

                <div className="pools__header--right">
                    <Button type="primary" onClick={() => toggle(METHOD_ADD)}>
                        Add new
                    </Button>
                </div>
            </div>

            <PrizePoolsTable
                data={pools}
                isLoading={status === STATUS_LOADING}
                totalCount={totalCount}
                onPageChange={onPageChange}
                currentPage={requestData.pageNumber + 1}
                edit={edit}
                onDelete={showDeleteAlter}
                onCopyClick={showCopyModal}
            />

            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={method === METHOD_EDIT ? "Edit pool" : "Add pool"}
            >
                <Tabs tabs={tabs} type="small" selectedIndex={selectedIndex}/>

                <div className="modal__footer">
                    <div className="modal__footer--btns">
                        <Button type="secondary" onClick={() => toggle(method)}>Cancel</Button>
                        <Button
                            type="primary"
                            onClick={() => save()}
                            isLoading={poolStatus === STATUS_LOADING}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isCopyModalOpen}
                setIsOpen={setIsCopyModalOpen}
                title={`Copy pool ${pools.find((p: Pool) => p.id === copyPoolPayload.poolId)?.title}`}
            >
                <div className="modal__content">
                    <div className="copy-pool__form">
                        <div className="form-control">
                            <div className="form-control__checkbox">
                                <Switch
                                    active={!!copyPoolPayload.copyCodes}
                                    handleChange={() => setCopyPoolPayload((payload) => ({ ...payload, copyCodes: !payload.copyCodes }))}
                                />
                            </div>
                            <div className="form-control__label">
                                <span>Generate codes</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal__footer modal__footer--copy">
                    <div className="modal__footer--btns">
                        <Button type="secondary" onClick={() => setIsCopyModalOpen(false)} disabled={isCopyInProgress}>Cancel</Button>
                        <Button type="primary" onClick={() => copyPool()} disabled={isCopyInProgress} isLoading={isCopyInProgress}>Copy</Button>
                    </div>
                </div>
            </Modal>

            <Confirm
                isOpen={isDeleteAlertOpen}
                setIsOpen={setIsDeleteAlertOpen}
                title="Delete pool"
                text="Are you sure you want to delete this pool?"
                buttonText="Yes"
                type="danger"
                action={deletePool}
            />
        </div>
    );
};

export default PrizePools;
