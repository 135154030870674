
import axios from "axios";
import TokenService from "../../auth/TokenService";

export const api = axios.create({
    baseURL:  process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${TokenService.getCookieAccessToken()}`,
    },
});

export const apiOld = axios.create({
    baseURL:  process.env.REACT_APP_OLD_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${TokenService.getCookieAccessToken()}`,
    },
})
