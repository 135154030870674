import React from "react";
import { Combobox } from "@headlessui/react";

import "./SearchAutoComplete.scss";
import Highlighted from "../Highlighted/Highlighted";

type Props = {
    options: Array<{id: string, label: string}>,
    selectedOption: {id: string, label: string},
    handleSelectChange: Function,
    handleSearch: Function,
    searchValue: string,
    disabled?: boolean,
    placeholder?: string
}

const SearchAutoComplete = ({options, selectedOption, handleSelectChange, handleSearch, searchValue, disabled, placeholder}: Props) => {

    return (
        <Combobox value={selectedOption.label} disabled={disabled} onChange={(e) => handleSelectChange(e)} as="div" className="search-autocomplete">
            <Combobox.Input className="input" onChange={(e) => handleSearch(e)} placeholder={placeholder}/>
            <Combobox.Options className="search-autocomplete__menu">
                {options.map((option) => (
                    <Combobox.Option key={option.id} value={option.id}>
                        <Highlighted
                            text={option.label}
                            highlight={searchValue}
                        />
                    </Combobox.Option>
                ))}
            </Combobox.Options>
        </Combobox>
    )
}

export default SearchAutoComplete;
