import { Dispatch, createSlice } from "@reduxjs/toolkit";
import { STATUS_IDLE, STATUS_LOADING, STATUS_FAILED } from "../../constants/constants";
import * as poolsApi from "../api/pools";

type Status = typeof STATUS_IDLE | typeof STATUS_LOADING | typeof STATUS_FAILED;

export interface CopyState {
    pool: {
        status: Status
    },
    prize: {
        status: Status
    }
}

const initialState: CopyState = {
    pool: {
        status: STATUS_IDLE
    },
    prize: {
        status: STATUS_IDLE
    }
};

const poolCopySlice = createSlice({
    name: "copy",
    initialState,
    reducers: {
        poolCopyStarted: (state) => {
            state.pool.status = STATUS_LOADING;
        },
        poolCopySuccess: (state) => {
            state.pool.status = STATUS_IDLE;
        },
        poolCopyFailed: (state) => {
            state.pool.status = STATUS_FAILED;
        }
    }
});

const { poolCopyStarted, poolCopySuccess, poolCopyFailed } = poolCopySlice.actions;

export const copyPool = (id: number, payload: { copyCodes?: boolean }) => {
    return async (dispatch: Dispatch) => {
        dispatch(poolCopyStarted());

        try {
            await poolsApi.copyPool(id, payload);
            dispatch(poolCopySuccess());
        } catch (e) {
            console.error(e);
            dispatch(poolCopyFailed());
        }
    };
};

export default poolCopySlice.reducer;
