import React, {useState} from "react";

import Input from "../../../shared/Input/Input";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import "./LimitsAndProbability.scss";
import Timeline from "../../../shared/Timeline/Timeline";
import {Pool} from "../../../../interfaces/Pool";
import {
    LIMIT_DIFFERENT,
    LIMIT_SAME,
    LIMIT_UNLIMITED,
    PROBABILITY_DIFFERENT,
    PROBABILITY_SAME
} from "../../../../constants/constants";
import {getPercentage} from "../../../../util/util";
import { WinProbability } from "../../../../interfaces/WinProbability";

const limitOptions = [
    {
        id: LIMIT_SAME,
        label: "Same limit for all days"
    },
    {
        id: LIMIT_DIFFERENT,
        label: "Different limit for each day"
    },
    {
        id: LIMIT_UNLIMITED,
        label: "Unlimited"
    }
];

const probabilityOptions = [
    {
        id: PROBABILITY_SAME,
        label: "The same probability of winning a prize for all days"
    },
    {
        id: PROBABILITY_DIFFERENT,
        label: "Different probability for each day separately"
    }
];

type Props = {
    pool: Pool,
    handleDataChange: Function,
    winsPerPlayError: boolean,
    numberPerPlayError: boolean,
}

const LimitsAndProbability = ({pool, handleDataChange, winsPerPlayError, numberPerPlayError}: Props) => {
    const [openProbability, setOpenProbability] = useState<number>(0);
    const [openLimit, setOpenLimit] = useState<number>(0);


    const changeLimitType = (option: { id: number | string, label: string }) => {
        handleDataChange({
            ...pool,
            prizeLimitType: option.id,
            prizeLimitConstant: 0
        });
    }

    const changeProbabilityType = (option: { id: number | string, label: string }) => {
        handleDataChange({
            ...pool,
            winProbabilityType: option.id,
            wins: 1,
            perPlay: 100
        });
    }

    const handleInputChange = (event: any) => {
        let value = Math.abs(Number(event.target.value));
        const name = event.target.name;
        const winProbabilityPerDay = name === 'durationInDays' ? truncateWinProbabilityPerDay(pool.winProbabilityPerDay, value) : pool.winProbabilityPerDay;

        handleDataChange({...pool, winProbabilityPerDay, [name]: value});
    }

    const truncateWinProbabilityPerDay = (data: WinProbability[], value: number) => {
        return data.filter((probability) => probability.onDate <= value);
    }

    const getLimitType = () => {
        return limitOptions.find(option => option.id === pool.prizeLimitType) ?? limitOptions[0];
    }

    const getProbabilityType = () => {
        return probabilityOptions.find(option => option.id === pool.winProbabilityType) ?? probabilityOptions[0];
    }

    const handleOnClickCapture = (e: any) => {
        const className = e.target.className;
        if (className !== "clickable limit" && className !== "input limit") {
            setOpenLimit(0);
        }
        if (className !== "clickable" && className !== "double-input") {
            setOpenProbability(0);
        }
    }

    return (
        <div className="limits" onClickCapture={handleOnClickCapture}>
            {
                winsPerPlayError && (
                    <span className="error-message">Wins per play can't be higher than 100 percent.</span>
                )
            }
            {
                numberPerPlayError && (
                    <span className="error-message">Number per play can't be 0.</span>
                )
            }
            <div className="limits__duration">
                <span>Duration</span>
                <Input
                    placeholder="0"
                    name="durationInDays"
                    type="number"
                    onChange={handleInputChange}
                    value={pool.durationInDays ? pool.durationInDays : ""}
                    min="0"
                />
                <span>days</span>
            </div>

            <div className="limits__type">
                <Dropdown
                    data={limitOptions}
                    selectedValue={getLimitType()}
                    handleChange={changeLimitType}
                />
                {
                    getLimitType().id === LIMIT_SAME && (
                        <Input
                            placeholder="10000"
                            name="prizeLimitConstant"
                            type="number"
                            onChange={handleInputChange}
                            value={pool.prizeLimitConstant ? pool.prizeLimitConstant : ""}
                            min="0"
                        />
                    )
                }

            </div>

            <div className="limits__probability">
                <Dropdown
                    data={probabilityOptions}
                    selectedValue={getProbabilityType()}
                    handleChange={changeProbabilityType}
                />
                {
                    getProbabilityType().id === PROBABILITY_SAME && (
                        <>
                            <Input
                                placeholder="10"
                                type="number"
                                name="wins"
                                onChange={handleInputChange}
                                value={pool.wins ? pool.wins : ""}
                                min="1"
                            />
                            <span>per</span>
                            <Input
                                placeholder="100"
                                type="number"
                                name="perPlay"
                                onChange={handleInputChange}
                                value={pool.perPlay ? pool.perPlay : ""}
                                min="1"
                            />
                            <span>plays</span>
                            <Input type="text" name="percentage" readOnly={true} value={getPercentage(pool.wins, pool.perPlay) + "%"}/>
                        </>
                    )
                }
            </div>

            <div className="limits__timeline">
                <Timeline
                    pool={pool}
                    handleDataChange={handleDataChange}
                    openProbability={openProbability}
                    setOpenProbability={setOpenProbability}
                    openLimit={openLimit}
                    setOpenLimit={setOpenLimit}
                />
            </div>
        </div>
    );
}

export default LimitsAndProbability;
