import React, {useState} from "react";

import {ReactComponent as Info} from "../../../assets/icons/info.svg";
import ReactTooltip from "react-tooltip";

type Props = {
    heading: string,
    infoText?: string | React.ReactNode,
}

const TableHeadCell: React.FC<Props> = ({heading, infoText}) => {
    const [tooltip, showTooltip] = useState(true);

    return (
        <th>
            {heading}
            {
                infoText && (
                    <>
                        <Info
                            data-tip data-for="info"
                            onMouseEnter={() => showTooltip(true)}
                            onMouseLeave={() => {
                                showTooltip(false);
                                setTimeout(() => showTooltip(true), 50);
                            }}
                        />
                        {
                            tooltip &&
                            <ReactTooltip id="info" type="success" place="top" effect="solid" className="brame-tooltip">
                                {infoText}
                            </ReactTooltip>
                        }
                    </>
                )
            }
        </th>
    );
};

export default TableHeadCell;
