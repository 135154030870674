import React, {useEffect} from "react";

import "./Timeline.scss";
import {Pool} from "../../../interfaces/Pool";
import {
    FIRST_VALUE,
    LIMIT_DIFFERENT,
    LIMIT_UNLIMITED,
    PROBABILITY_DIFFERENT,
    SECOND_VALUE
} from "../../../constants/constants";
import DoubleInput from "../DoubleInput/DoubleInput";
import {ReactComponent as InfinityIcon} from "../../../assets/icons/infinity.svg";

type Props = {
    pool: Pool,
    handleDataChange: Function,
    openProbability: number,
    setOpenProbability: Function,
    openLimit: number,
    setOpenLimit: Function
}

const Timeline = (props: Props) => {
    const {
        pool,
        handleDataChange,
        openProbability,
        setOpenProbability,
        openLimit,
        setOpenLimit
    } = props;

    // daysArray is just as mock array with the same length as duration
    const daysArray = new Array(pool.durationInDays).fill(pool.durationInDays);

    useEffect(() => {
        setOpenLimit(0);
        setOpenProbability(0);
    }, [pool.prizeLimitType, pool.winProbabilityType, setOpenProbability, setOpenLimit]);

    const handleDayLimitChange = (e: any) => {
        const prizeLimitPerDay = [...pool.prizeLimitPerDay];
        const limitIndex = prizeLimitPerDay.findIndex((limit) => limit.onDate === openLimit);
        if (limitIndex !== -1) {
            const prizeLimit = {...prizeLimitPerDay[limitIndex]};
            prizeLimit.prizeLimit = Math.abs(Number(e.target.value));
            prizeLimitPerDay[limitIndex] = prizeLimit;
        } else {
            prizeLimitPerDay.push({
                onDate: openLimit,
                prizeLimit: Math.abs(Number(e.target.value)),
                id: Math.random()
            })
        }

        handleDataChange({...pool, prizeLimitPerDay});
    }

    const getPrizeLimit = (day: number) => {
        const limit =  pool.prizeLimitPerDay.find((limit) => limit.onDate === day);
        return limit ? limit.prizeLimit : "";
    }

    const getProbability = (day: number, field: string) => {
        const probability = pool.winProbabilityPerDay.find((probability) => probability.onDate === day);
        // @ts-ignore
        return probability ? probability[field] : "";
    }

    const handleDayProbabilityChange = (e: any) => {
        const winProbabilityPerDay = [...pool.winProbabilityPerDay];
        const winIndex = winProbabilityPerDay.findIndex((win) => win.onDate === openProbability);
        const name = e.target.name;
        const value = Math.abs(Number(e.target.value));
        if (winIndex !== -1) {
            const winProbability = {...winProbabilityPerDay[winIndex]};
            if (name === FIRST_VALUE) {
                winProbability.wins = value;
            } else {
                winProbability.perPlay = value;
            }
            winProbabilityPerDay[winIndex] = winProbability;
        } else {
            const wins = name === FIRST_VALUE ? value : 0;
            const perPlay = name === SECOND_VALUE ? value : 0;
            winProbabilityPerDay.push({
                id: Math.random(),
                onDate: openProbability,
                perPlay,
                wins
            })
        }

        handleDataChange({...pool, winProbabilityPerDay});
    }

    return (
        <div className="timeline">
            <table>
                <thead>
                <tr>
                    <th></th>
                    {
                        daysArray.map((item, index) => (
                            <th key={index}>Day {index + 1}</th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Prize limit</td>
                    {
                        daysArray.map((item, index) => {
                            if (pool.prizeLimitType === LIMIT_DIFFERENT) {
                                if (openLimit === (index + 1)) {
                                    return (
                                        <td key={index} className="with-input limit" onBlur={() => setOpenLimit(0)}>
                                            <input
                                                name={`day_${index + 1}`}
                                                type="number"
                                                className="input limit"
                                                value={getPrizeLimit(index + 1)}
                                                onChange={handleDayLimitChange}
                                                placeholder="0"
                                                min={1}
                                                max={10000}
                                            />
                                        </td>
                                    )
                                }
                                return (
                                    <td
                                        key={index}
                                        onClick={() => setOpenLimit(index + 1)}
                                        className="clickable limit"
                                    >
                                        {getPrizeLimit(index + 1)}
                                    </td>
                                )
                            }

                            if (pool.prizeLimitType === LIMIT_UNLIMITED) {
                                return <td key={index}>
                                    <InfinityIcon/>
                                </td>
                            }

                            return <td key={index}>{pool.prizeLimitConstant}</td>
                        })
                    }
                </tr>
                <tr>
                    <td>Probability</td>
                    {
                        daysArray.map((item, index) => {
                            if (pool.winProbabilityType === PROBABILITY_DIFFERENT) {
                                if (openProbability === (index + 1)) {
                                    return (
                                        <td key={index} className="with-input double">
                                            <DoubleInput
                                                firstValue={getProbability(index + 1, "wins")}
                                                handleFirstValueChange={handleDayProbabilityChange}
                                                secondValue={getProbability(index + 1, "perPlay")}
                                                handleSecondValueChange={handleDayProbabilityChange}
                                                placeholders={["0", "0"]}
                                            />
                                        </td>
                                    )
                                }
                                return (
                                    <td
                                        key={index}
                                        onClick={() => setOpenProbability(index + 1)}
                                        className="clickable"
                                    >
                                        {getProbability(index + 1, "wins")}/{getProbability(index + 1, "perPlay")}
                                    </td>
                                )
                            }

                            return <td key={index}>{pool.wins}/{pool.perPlay}</td>
                        })
                    }
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Timeline;
