import React from "react";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableBody from "../../../shared/Table/TableBody";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableRow from "../../../shared/Table/TableRow";
import TableCell from "../../../shared/Table/TableCell";
import Pagination from "../../../shared/Pagination/Pagination";
import {Column} from "../../../../interfaces/Column";

import {ReactComponent as Edit} from "../../../../assets/icons/edit.svg";
import {ReactComponent as Delete} from "../../../../assets/icons/delete.svg";
import {stripHtmlTags} from "../../../../util/util";

type Props = {
    data: Array<any>,
    totalCount: number,
    isLoading: boolean,
    onPageChange: Function,
    currentPage: number,
    editPrize: Function,
    deletePrize: Function
}

const PrizesTable: React.FC<Props> = ({data, isLoading, totalCount, onPageChange, currentPage, editPrize, deletePrize}: Props) => {
    const columns: Array<Column> = [
        {
            label: "ID",
            value: "internalPrizeId",
        },
        {
            label: "Image",
            value: "gameImage",
            render: (data: any, columnValue: string) => (
                data[columnValue] ? (
                    <img src={data[columnValue]} alt="Prize"/>
                ) : (
                    <img src={require('./../../../../assets/images/white.png')} alt="Prize"/>
                )
            )
        },
        {
            label: "Prize title",
            value: "title"
        },
        {
            label: "Prize description",
            value: "description",
            render: (data: any, columnValue) => (
                <span
                    className="elipsis-200"
                    title={stripHtmlTags(data[columnValue])}
                >
                    {stripHtmlTags(data[columnValue])}
                </span>
            )
        },
        {
            label: "Amount",
            value: "amount"
        },
        {
            label: "Sent Out / Redeemed",
            value: "sentOut",
            render: (data: any) => (
                <span>{data.sentOut ?? 0}/{data.redeemed ?? 0}</span>
            )
        },
        {
            label: "Avaliable",
            value: "available"
        },
        {
            label: "Included in pool",
            value: "pools",
            render: (data: any, columnValue: string) => {
                const pools = data[columnValue].map((pool: any) => {
                    return pool.title;
                });

                return <span className="elipsis-140">{ pools.join(", ") }</span>;
            }
        },
        {
            label: "",
            value: "delete",
            render: (data: any) => (
                <Delete onClick={() => deletePrize(data.id)}/>
            )
        },
        {
            label: "",
            value: "edit",
            render: (data: any) => (
                <Edit onClick={() => editPrize(data.id)}/>
            )
        }
    ];

    const renderTableCell = (row: any, column: any, index: number) => {
        return (
            <TableCell key={index}>
                {
                    column.render ? (
                        column.render(row, column.value)
                    ) : (
                        <span className="elipsis-200">
                            {row[column.value]}
                        </span>
                    )
                }
            </TableCell>
        )
    }

    return (
        <ErrorBoundary>
            <Loader isLoading={isLoading}>
                <Table>
                    <TableHead>
                        {columns.map(({label}, index) => <TableHeadCell key={index} heading={label}/>)}
                    </TableHead>
                    <TableBody>
                        {
                            data.length > 0 ? (
                                data.map((row, index) => (
                                    <TableRow key={index}>
                                        {
                                            columns.map((column, i) => (
                                                renderTableCell(row, column, i)
                                            ))
                                        }
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <td className="no-data" colSpan={columns.length}>No data</td>
                                </TableRow>
                            )
                        }
                    </TableBody>
                    <Pagination
                        colSpan={columns.length}
                        totalCount={totalCount}
                        showPerPage={8}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                    />
                </Table>
            </Loader>
        </ErrorBoundary>
    );
};

export default PrizesTable;
