import { AxiosError } from "axios";
import { LIMIT_DIFFERENT, LIMIT_SAME, PROBABILITY_DIFFERENT, PROBABILITY_SAME, TIME_ZONE_STORAGE_KEY } from "../constants/constants";
import { Pool } from "../interfaces/Pool";

/**
 * Format string ex. spin-the-wheel to Spin the wheel
 * @param type
 */
export const formatCampaignType = (type: string) => {
    const regex = /-/g;
    let str = type.replace(regex, " ");
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Formate date time string ex. 31-07-2022 18:01:11 to 31.07.2022 18:01
 * @param dateTime
 */
export const formatDateTime = (dateTime: string) => {
    const dateArray = dateTime.split(" ");
    let date = dateArray[0];
    let time = dateArray[1];
    const regex = /-/g;
    date = date.replace(regex, ".");
    time = time.slice(0, time.length - 3);
    return date + " " + time;
}

export const getSystemTimeZoneOffset = () => -60000 * new Date().getTimezoneOffset();

export const getCompanyTimeZoneOffset = () => {
    try {
        const timeZoneString = localStorage.getItem(TIME_ZONE_STORAGE_KEY);

        if (timeZoneString) {
            return +(JSON.parse(timeZoneString) as { gmtOffset: string }).gmtOffset * 1000;
        }

        return getSystemTimeZoneOffset();
    } catch (e) {
        return getSystemTimeZoneOffset();
    }
};

export const parseISODateString = (dateString: string, offset = getCompanyTimeZoneOffset()) => {
    const date = new Date(dateString);

    return new Date(date.getTime() + 60000 * date.getTimezoneOffset() + offset);
};

export const zeroLeftPad = (value: number) => {
    return value < 10 ? `0${value}` : `${value}`;
};

export const formatISODate = (dateString: string, offset = getCompanyTimeZoneOffset()) => {
    const date = parseISODateString(dateString, offset);

    const datePart = [date.getDate(), date.getMonth() + 1, date.getFullYear()].map(zeroLeftPad);
    const timePart = [date.getHours(), date.getMinutes()].map(zeroLeftPad);

    return `${datePart.join('.')} ${timePart.join(':')}`;
};

/**
 * Format date time string and return only date without time
 * @param dateTime
 */
export const getDate = (dateTime: string) => {
    const dateArray = dateTime.split(" ");
    let date = dateArray[0];
    const newDateArray = date.split("-");
    return `${newDateArray[2]}.${newDateArray[1]}.${newDateArray[0]}`;
}

/**
 *
 * @param startDate
 * @param duration
 */
export const calculateCampaignEndDate = (startDate: string, duration: number) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + duration);
    const dateString = date.toISOString().slice(0,10);
    const dateArray = dateString.split("-");
    return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
}

/**
 *
 * @param dateTime
 */
export const subtractOneSecondFromDate = (dateTime: string) => {
    const date = new Date(dateTime);
    date.setDate(date.getDate()-1);
    const timestamp = date.setSeconds(date.getSeconds() - 1);
    const newHours = new Date(timestamp).setHours(23, 59, 59);
    const newDate = new Date(newHours);
    const year = newDate.getFullYear();
    let month: string | number = newDate.getMonth() + 1;
    let day: string | number = newDate.getDate();
    let hours: string | number = newDate.getHours();
    let minutes = newDate.getMinutes();

    if (day < 10) {
        day = "0" + day;
    }
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (month < 10) {
        month = "0" + month;
    }

    return `${day}.${month}.${year} ${hours}:${minutes}`
}

/**
 * Check if date is in the past
 * @param dateTime
 */
export const dateTimeInPast = (dateTime: string) => {
    const date = new Date(dateTime);
    const today = new Date();
    return date <= today;
};

/**
 *
 * @param file
 */
export const toBase64 = (file: File) => {
    return new Promise(resolve => {
        let baseURL;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

/**
 * Check if string is valid url
 * @param str
 */
export const isValidUrl = (str: string) => {
    try {
        new URL(str);
    } catch (_) {
        return false;
    }

    return true;
}

/**
 * Calculate percentage
 * @param partialValue
 * @param totalValue
 */
export const getPercentage = (partialValue: number, totalValue: number) => {
    if (!partialValue || !totalValue) {
        return 0;
    }
    return ((100 * partialValue) / totalValue).toFixed(1);
}

/**
 *  Check if string can be parsed to json
 * @param value
 */
export const isValidJsonString = (value: string) => {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
}

/**
 * Build url based on object of parameters
 * @param baseUrl
 * @param params
 */
export const buildUrl = (baseUrl: string, params: Object) => {
    const queryString = Object.keys(params)
        // @ts-ignore
        .map((key) => `${key}=${encodeURIComponent(String(params[key]))}`)
        .join("&");
    return baseUrl +  "?" + queryString;
}

/**
 * Truncate text with specific char length
 * @param input
 * @param charCount
 */
export const truncateText = (input: string, charCount: number) => (
    input.length > charCount ? `${input.substring(0, charCount)}...` : input
);

/**
 * Strip html tags from text
 * @param text
 */
export const stripHtmlTags = (text: string) => {
    return text.replace(/<[^>]*>?/gm, " ");
}

/**
 * Parse JWT token
 * @param token
 */
export function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window.atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}

export function nYearsFromNow(n: number): Date {
    if (!Number.isInteger(n)) {
        throw new Error('years offset must be a valid integer');
    }

    const date = new Date();

    date.setFullYear(date.getFullYear() + n);

    return date;
}

export function parseUpdatePoolPayload(payload: Pool) {
    const {
        wins,
        perPlay,
        winProbabilityPerDay,
        prizeLimitPerDay,
        prizeLimitConstant,
        ...parsed
    } = payload;

    if (payload.winProbabilityType === PROBABILITY_DIFFERENT) {
        (parsed as any).winProbabilityPerDay = winProbabilityPerDay;
    } else if (payload.winProbabilityType === PROBABILITY_SAME) {
        (parsed as any).wins = wins;
        (parsed as any).perPlay = perPlay;
    }

    if (payload.prizeLimitType === LIMIT_DIFFERENT) {
        (parsed as any).prizeLimitPerDay = prizeLimitPerDay;
    } else if (payload.prizeLimitType === LIMIT_SAME) {
        (parsed as any).prizeLimitConstant = prizeLimitConstant;
    }

    if (!payload.image) {
        parsed.image = '';
    }

    return parsed as Pool;
}

export function parseApiErrors(error: AxiosError<{ errors: Record<string, boolean> }>): Record<string, boolean> | null {
    if (error.response && error.response.data && error.response.data.errors) {
        return error.response.data.errors;
    }

    return null;
}

export function formatPrizeExpirationDate(expirationDate: string, offset = getCompanyTimeZoneOffset()): string {
    return formatISODate(new Date(new Date(expirationDate).getTime() - 1000).toISOString(), offset);
}
