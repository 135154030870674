import React, {ChangeEventHandler, Component, RefObject} from "react";

import {ReactComponent as UploadIcon} from "../../../assets/icons/upload.svg";
import "./UploadImage.scss";

type Props = {
    text: string,
    height: number,
    width?: number,
    preview: string | null,
    name?: string,
    title?: string,
    onChange: ChangeEventHandler<HTMLInputElement>
}

type State = {
    file: File | null,
    preview: string
}

class UploadImage extends Component<Props, State> {
    input: RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.input = React.createRef();

        this.state = {
            file: null,
            preview: this.props.preview ? this.props.preview : ""
        }

        this.onFileChange = this.onFileChange.bind(this);
    }

    onFileChange(e: any) {
        if (!["image/png", "image/gif", "image/jpeg"].includes(e.target.files[0].type)) {
            return;
        }
        const url = URL.createObjectURL(e.target.files[0]);
        this.setState({
            file: e.target.files[0],
            preview: url
        })
        this.props.onChange(e);
    }

    render() {
        return (
            <div className="upload" onClick={() => this.input.current && this.input.current.click()}
                 style={{height: this.props.height + "px", width: this.props.width + "px"}}
                 title={this.props.title}
            >
                <input name={this.props.name} type="file" onChange={this.onFileChange} ref={this.input}
                       accept="image/png, image/gif, image/jpeg"/>
                {
                    this.state.preview ? (
                        <div className="upload__img" style={{height: this.props.height - 58 + "px"}}>
                            <img src={this.state.preview} alt="Result"/>
                        </div>
                    ) : (
                        <div className={`upload__icon ${this.props.name}`} style={{height: this.props.height - 58 + "px"}}>
                            <UploadIcon/>
                        </div>
                    )
                }

                <div className="upload__text">
                    <span
                        className={this.state.file ? "elipsis-200" : ""}>{this.state.file ? this.state.file.name : this.props.text}</span>
                </div>
            </div>
        );
    }
}

export default UploadImage;
