import React, {Fragment} from "react";

type Props = {
    children: React.ReactNode
    expanded?: boolean;
    expandedData?: any
}

const TableRow: React.FC<Props> = ({children, expanded, expandedData}) => {
    return (
        <Fragment>
            <tr>
                {children}
            </tr>
            {expanded ? (
                    <tr className="expanded-row">
                        {expandedData}
                    </tr>
                ) : null}
        </Fragment>
    );
};

export default TableRow;
