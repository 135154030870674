import { CommonCodeBatch } from "../../interfaces/Batch";
import {api} from "./api";

export const createBatch = (data: FormData) => {
    return api.post("prize-management/batch/create", data)
        .then((result) => result)
}

export const putBatch = (data: any, batchId: number) => {
    return api.put(`prize-management/batch/${batchId}`, data)
        .then((result) => result)
}

export const getBatches = (prizeId: number, type?: string) => {
    const params = {
        type: type
    };
    return api.get(`prize-management/batches/${prizeId}`, {params})
        .then((result) => result)
}

export const deleteBatch = (id: number) => {
    return api.delete(`prize-management/batches/delete/${id}`)
        .then((result) => result);
}

export const saveCommonCodeBatch = (prizeId: number, batch: CommonCodeBatch) => {
    if (!batch.id) {
        const data = new FormData();

        data.append('prizeId', `${prizeId}`);
        data.append('codesAmount', `${batch.codesAmount}`);
        data.append('commonCode', batch.commonCode);
        data.append('codeValidity', `${batch.codeValidity}`);
        data.append('expiration', `${batch.expiration}`);
        data.append('batchType', `${batch.batchType}`);
        data.append('codeType', `${batch.codeType}`);

        return api.post('/prize-management/batch/create', data);
    }

    const { id, ...payload } = batch;

    return api.put(`prize-management/batch/${batch.id}`, payload);
}
