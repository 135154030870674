import {createSlice, Dispatch} from "@reduxjs/toolkit";

import {RootState} from "../store";
import {generateWin} from "../api/winners";
import {STATUS_FAILED, STATUS_IDLE, STATUS_LOADING} from "../../constants/constants";
import {Win} from "../../interfaces/Win";

export interface WinState {
    data: Win | null;
    status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: WinState = {
    data: null,
    status: STATUS_IDLE,
};

const generateWinSlice = createSlice({
    name: "win",
    initialState,
    reducers: {
        generateWinStarted: (state) => {
            state.status = STATUS_LOADING;
        },
        generateWinSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data;
            } else {
                state.data = initialState.data;
            }

        },
        generateWinError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.code, action.payload.message);
        }
    },
});

const {generateWinStarted, generateWinSuccess, generateWinError} = generateWinSlice.actions;

export const selectWin = (state: RootState) => state.win.data;
export const selectWinStatus = (state: RootState) => state.win.status;

export default generateWinSlice.reducer;

export const createWin = (data: Win) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(generateWinStarted());
            const result = await generateWin(data);
            dispatch(generateWinSuccess(result.data));
        } catch (error) {
            dispatch(generateWinError(error))
        }
    }
}
