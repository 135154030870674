import React, {ChangeEventHandler} from "react";

import "./Checkbox.scss";

type Props = {
    checked: boolean,
    handleChange?: ChangeEventHandler<HTMLInputElement>,
    disabled?: boolean,
    label?: string,
    value?: string | number
}

const Checkbox = ({checked, disabled, label, handleChange, value}: Props) => {
    return (
        <label className="checkbox">
            <input
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
                value={value}
            />
            <span>{label ? label : ""}</span>
        </label>
    );
};

export default Checkbox;
