import React, {Component} from "react";
import "./BatchesEdit.scss";
import "react-datepicker/dist/react-datepicker.css";

import {ReactComponent as Calendar} from "../../../../assets/icons/calendar.svg";
import Input from "../../../shared/Input/Input";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import DatePicker from "react-datepicker";
import Button from "../../../shared/Button/Button";
import Modal from "../../../shared/Modal/Modal";
import CodesList from "../CodesList/CodesList";


type Props = {
    onChange: Function;
    data: any;
}

type State = {
    date: Date | null,
    isOpenListModal: boolean
}

const options = [
    {
        id: "EAN128",
        label: "EAN128"
    },
    {
        id: "EAN13",
        label: "EAN13"
    },
    {
        id: "QR",
        label: "QR Code"
    }
];

class BatchesEdit extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            date: new Date(props.data.expiration),
            isOpenListModal: false
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleBarCodeChange = this.handleBarCodeChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.getSelectedCodeType = this.getSelectedCodeType.bind(this);
    }

    setIsOpenListModal() {
        this.setState({isOpenListModal: !this.state.isOpenListModal})
    }

    onInputChange(event: any) {
        let value = event.target.value;
        const name = event.target.name;
        if (event.target.name === "codeValidity") {
            value = Number(event.target.value);
        }
        this.props.onChange({...this.props.data, [name]: value});
    }

    handleDateChange(event: Date | null) {
        this.setState({date: event})
        this.props.onChange({...this.props.data, "expiration": event});
    }

    handleBarCodeChange(event: any) {
        this.props.onChange({...this.props.data, "codeType": event.id});
    }

    getSelectedCodeType() {
        return options.filter((option) => option.id === this.props.data.codeType)[0] ?? options[0];
    }

    render() {
        return (
            <div className="codes-edit">
                <div className="codes-edit__top">
                    <div className="codes-validity">
                        <p className="codes-edit__label">Code Validity</p>
                        <Input placeholder="0" name="codeValidity" type="number" min="0"
                               value={this.props.data.codeValidity ?? 0} onChange={this.onInputChange}/>
                    </div>
                    <div className="codes-expiration">
                        <p className="codes-edit__label">Code Expiration</p>
                        <Calendar/>
                        <DatePicker
                            selected={this.state.date}
                            onChange={(e) => this.handleDateChange(e)} //only when value has changed
                        />
                    </div>
                    <div className="codes-show">
                        <Button type="primary" onClick={() => this.setIsOpenListModal()}>
                            Show Codes
                        </Button>
                    </div>
                </div>
                <div className="codes-edit__bottom">
                    <div className="codes-dropdown">
                        <p className="codes-edit__label">Code Type</p>
                        <Dropdown data={options} selectedValue={this.getSelectedCodeType()} handleChange={this.handleBarCodeChange}/>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.isOpenListModal}
                    setIsOpen={() => this.setIsOpenListModal()}
                    title={`List of ${this.props.data.codeType} codes`}
                    customClass="codes-list-modal"
                >
                    <CodesList batchId={this.props.data.id}></CodesList>
                    <div className="modal__footer">
                        <div className="modal__footer--btns">
                            <Button type="secondary" onClick={() => this.setIsOpenListModal()}>Cancel</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default BatchesEdit;
