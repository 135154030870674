import {api} from "./api";
import {Win} from "../../interfaces/Win";

interface RequestBody {
    searchTerm: string,
    campaignId: string,
    perPage: number,
    pageNumber: number,
    sortBy: string,
    order: "ASC" | "DESC"
}

export const searchWinners = (data: RequestBody) => {
    return api.post("/prize-management/winners/search", data)
        .then((result) => result);
}

export const resendPrizeCode = (data: { uuid: string; campaignId: string }) => {
    return api.post("/prize-management/winners/resend-code", data)
        .then((result) => result);
}

export const redeemPrizeCode = (data: { playId: string; campaignId: string }) => {
    return api.post("/prize-management/winners/redeem-prize", data)
        .then((result) => result);
}

export const getPrizes = (campaignId: string) => {
    return api.get(`/prize-management/winners/${campaignId}/prizes`)
        .then((result) => result);
}

export const generateWin = (data: Win) => {
    return api.post("/prize-management/winners/generate-win", data)
        .then((result) => result);
}

