import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {redeemPrizeCode} from "../api/winners";
import {STATUS_FAILED, STATUS_IDLE, STATUS_LOADING} from "../../constants/constants";

export interface RedeemPrizeState {
    data: string;
    status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: RedeemPrizeState = {
    data: "",
    status: STATUS_IDLE,
};

const redeemPrizeSlice = createSlice({
    name: "redeemPrize",
    initialState,
    reducers: {
        redeemPrizeStarted: (state) => {
            state.status = STATUS_LOADING;
        },
        redeemPrizeSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data;
            } else {
                state.data = initialState.data;
            }

        },
        redeemPrizeError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.code, action.payload.message);
        }
    },
});

const {redeemPrizeStarted, redeemPrizeSuccess, redeemPrizeError} = redeemPrizeSlice.actions;

export const selectRedeemPrize = (state: RootState) => state.redeemPrize.data;
export const selectStatus = (state: RootState) => state.redeemPrize.status;

export default redeemPrizeSlice.reducer;

export const redeemPrize = (data: {playId: string; campaignId: string}) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(redeemPrizeStarted());
            const result = await redeemPrizeCode(data);
            dispatch(redeemPrizeSuccess(result.data));
        } catch (error) {
            dispatch(redeemPrizeError(error))
        }
    }
}
