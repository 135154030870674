import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {Pool} from "../../interfaces/Pool";
import {createPool, getPool, putPool} from "../api/pools";
import {modal} from "./notificationSlice";
import {STATUS_FAILED, STATUS_IDLE, STATUS_LOADING} from "../../constants/constants";
import { parseUpdatePoolPayload } from "../../util/util";

export interface PoolState {
    data: Array<Pool>;
    status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: PoolState = {
    data: [],
    status: STATUS_IDLE
};

const poolSlice = createSlice({
    name: "pool",
    initialState,
    reducers: {
        getPoolStarted: (state) => {
            state.status = STATUS_LOADING;
        },
        getPoolSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data[0];
            } else {
                state.data = initialState.data;
            }

        },
        getPoolError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.code, action.payload.message);
        },
        createPoolStarted: (state) => {
            state.status = STATUS_LOADING;
        },
        createPoolSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data[0];
            } else {
                state.data = initialState.data;
            }

        },
        createPoolError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.message);
        },
        updatePoolStarted: (state) => {
            state.status = STATUS_LOADING;
        },
        updatePoolSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data;
            } else {
                state.data = initialState.data;
            }

        },
        updatePoolError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.message);
        }
    },
});

const {
    getPoolStarted,
    getPoolSuccess,
    getPoolError,
    createPoolStarted,
    createPoolSuccess,
    createPoolError,
} = poolSlice.actions;

export const selectPool = (state: RootState) => state.pool.data;
export const selectStatus = (state: RootState) => state.pool.status;

export default poolSlice.reducer;

export const fetchPool = (id: number) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(getPoolStarted());
            const result = await getPool(id);
            dispatch(getPoolSuccess(result.data));
        } catch (error) {
            dispatch(getPoolError(error))
        }
    }
}

export const addPool = (data: Pool) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(createPoolStarted());
            const result = await createPool(data);
            dispatch(createPoolSuccess(result.data));
            dispatch(modal.notify({
                    message: "Pool successfully created.",
                    type: "success"
                })
            );
        } catch (error) {
            dispatch(createPoolError(error));
            dispatch(modal.notify({
                    message: "Something went wrong.",
                    type: "error"
                })
            );
        }
    }
}

export const updatePool = (data: Pool) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(createPoolStarted());
            const result = await putPool(parseUpdatePoolPayload(data));
            dispatch(createPoolSuccess(result.data));
        } catch (error) {
            dispatch(createPoolError(error));
            dispatch(modal.notify({
                    message: "Something went wrong.",
                    type: "error"
                })
            );
        }
    }
}
