import {Dialog} from "@headlessui/react";
import React from "react";

import {ReactComponent as Close} from "../../../assets/icons/close.svg";
import Button from "../Button/Button";
import "./Confirm.scss";

type Props = {
    isOpen: boolean,
    setIsOpen: Function,
    title: string,
    text: string
    type: "primary" | "danger"
    buttonText: string,
    action: Function
}

const Confirm = (props: Props) => {
    const {isOpen, setIsOpen, title, text, type, buttonText, action} = props;
    return (
        <Dialog as="div" open={isOpen} onClose={() => setIsOpen} className={`alert ${type}`}>

            <div className="alert__backdrop " aria-hidden="true"/>

            <div className="alert__container">

                <Dialog.Panel className="alert__panel">
                    <Dialog.Title>
                        {title}
                        <div className="modal__close" onClick={() => setIsOpen(!isOpen)}>
                            <Close className={type}/>
                        </div>
                    </Dialog.Title>
                    <Dialog.Description as="div" className="alert__description">
                        {text}
                        <div className="alert__footer">
                            <Button type={type} onClick={() => action()}>
                                {buttonText}
                            </Button>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </div>
        </Dialog>
    )
};

export default Confirm;
