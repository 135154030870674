import React, {useEffect, useState} from "react";

import Tabs from "../../../shared/Tabs/Tabs";
import PrizeDetails from "../PrizeDetails/PrizeDetails";
import Batches from "../Batches/Batches";
import Search from "../../../shared/Search/Search";
import Button from "../../../shared/Button/Button";
import PrizesTable from "../PrizesTable/PrizesTable";
import Modal from "../../../shared/Modal/Modal";
import {fetchPrizes} from "../../../../data/slices/prizesSlice";
import {useAppDispatch, useAppSelector} from "../../../../data/hooks";
import "./Prizes.scss";
import InPools from "../InPools/InPools";
import {addPrize, emptyPrize, removePrize, updatePrize} from "../../../../data/slices/prizeSlice";
import {Prize, PrizeFactory} from "../../../../interfaces/Prize";
import {METHOD_ADD, METHOD_EDIT, STATUS_LOADING} from "../../../../constants/constants";
import Confirm from "../../../shared/Confirm/Confirm";
import ModalFooterData from "../ModalFooterData/ModalFooterData";
import FileUpload from "../../../shared/FileUpload/FileUpload";
import {addPrizes} from "../../../../data/slices/importPrizesSlice";
import { CommonCodeBatch } from "../../../../interfaces/Batch";
import { emptyBatch } from "../../../../data/slices/batchSlice";

const initialSearchPrizeData = {
    companyId: localStorage.getItem("companyId") || "",
    perPage: 8,
    pageNumber: 0,
    sortBy: "createdAt",
    order: "DESC",
    searchTerm: ""
}

const initialPrizeData: Prize = PrizeFactory.getInitialData();

const errorsInitialState = {
    "title": false,
    "daysValid": false
}

const Prizes = () => {
    const [isModalOpen, setIsOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [requestData, changeRequestData] = useState(initialSearchPrizeData);
    const [prize, changePrizeData] = useState(initialPrizeData);
    const [deletingPrize, setDeletingPrize] = useState(0);
    const [method, changeMethod] = useState(METHOD_ADD);
    const [errors, setErrors] = useState(errorsInitialState);
    const [isUploadOpen, setIsUploadOpen] = useState(false);
    const [file, updateFile] = useState<File | null>(null);
    const [fileError, setFileError] = useState(false);
    const [commonCodeBatch, setCommonCodeBatch] = useState<CommonCodeBatch | null>(null);

    const prizes = useAppSelector((state: any) => state.prizes.data);
    const totalCount = useAppSelector((state: any) => state.prizes.dataCount);
    const status = useAppSelector((state: any) => state.prizes.status);
    const addedPrize = useAppSelector((state: any) => state.prize.data);
    const prizeStatus = useAppSelector((state: any) => state.prize.status);
    const importPrizesStatus = useAppSelector((state: any) => state.importPrizes.status);
    const prizeApiErrors = useAppSelector((state: any) => state.prize.errors);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        changeRequestData({...requestData, companyId: companyId ?? ""});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isModalOpen) {
            dispatch(emptyPrize());
            dispatch(emptyBatch());
        }
    }, [isModalOpen, dispatch])

    const isTabDisabled = () => {
        const params = ["title", "daysValid"];
        const invalid = [];
        Object.keys(prize).forEach((param: string) => {
            // @ts-ignore
            if (params.includes(param) && !prize[param]) {
                invalid.push(param);
            }
        })
        return invalid.length > 0;
    }

    const dataChange = (data: any) => {
        changePrizeData(data);
        setErrors(errorsInitialState);
    }

    const onCodeTypeChange = (codeType: string) => {
        dataChange({  ...prize, codeType });
    }

    const onCommonCodeBatchChange = (batch: CommonCodeBatch | null) => {
        setCommonCodeBatch(batch);
    };

    const tabs = [
        {
            title: "Prize details",
            component: <PrizeDetails handleOnChange={dataChange} data={prize} errors={errors}/>,
        },
        {
            title: "Codes & Limits",
            component: <Batches data={prize} onCodeTypeChange={onCodeTypeChange} onCommonCodeBatchChangeCb={onCommonCodeBatchChange} />,
            disabled: isTabDisabled(),
            tooltip: isTabDisabled() ? "Please save the prize first." : ""
        },
        {
            title: "In Pools",
            component: <InPools handleOnChange={changePrizeData} data={prize}/>,
            disabled: isTabDisabled(),
            tooltip: isTabDisabled() ? "Please save the prize first." : ""
        }
    ];

    useEffect(() => {
        if (requestData.companyId) {
            dispatch(fetchPrizes(requestData));
        }
        if (addedPrize.id) {
            changePrizeData(addedPrize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestData])

    const toggle = (method: string) => {
        if (method === METHOD_ADD) {
            changePrizeData(initialPrizeData);
            setErrors(errorsInitialState);
            changeMethod(method);
            setIsOpen(!isModalOpen);
        } else {
            changeMethod(method);
            setIsOpen(!isModalOpen);
        }

    }

    const validate = () => {
        const errors = {
            "title": prize.title.length === 0,
            "daysValid": prize.daysValid === 0
        };
        setErrors(errors);
        return Object.values(errors).every(
            value => !value
        );
    }

    const save = () => {
        if (!validate()) {
            return;
        }
        if (method === METHOD_ADD) {
            dispatch(addPrize(prize)).then(() => {
                dispatch(fetchPrizes(requestData)).then(() => {
                    changeMethod(METHOD_EDIT);
                })
            });
        } else {
            if (prize.id) {
                const payload = commonCodeBatch ? { ...prize, commonCodeBatch } : prize;

                dispatch(updatePrize(payload, prize.id)).then(() => {
                    if (!prizeApiErrors) {
                        dispatch(fetchPrizes(requestData)).then(() => {
                            toggle(method);
                        });
                    }
                });
            }
        }
    }

    const onPageChange = (pageNumber: number) => {
        const page = pageNumber !== 0 ? pageNumber - 1 : pageNumber;
        changeRequestData({...requestData, pageNumber: page})
    }

    const search = (searchTerm: string) => {
        changeRequestData({...requestData, searchTerm});
    }

    const editPrize = (prizeId: number) => {
        changeMethod(METHOD_EDIT);
        setIsOpen(true);
        const prize = prizes.filter((prize: Prize) => prize.id === prizeId)[0];
        changePrizeData(prize);
    }

    const deleteAlert = (prizeId: number) => {
        setDeletingPrize(prizeId);
        setIsAlertOpen(true);
    }

    const deletePrize = () => {
        dispatch(removePrize(deletingPrize)).then(() => {
            setIsAlertOpen(false);
            const currentPrizes = prizes.filter((prize: Prize) => prize.id !== deletingPrize);
            if (currentPrizes.length === 0 && requestData.pageNumber > 0) {
                changeRequestData({...requestData, pageNumber: requestData.pageNumber - 1});
            } else {
                dispatch(fetchPrizes(requestData));
            }
        });
    }

    const onFileChange = (e: any) => {
        const files = e.target.files;
        if (files[0].type !== "text/csv") {
            return;
        }
        updateFile(files[0]);
    }

    const importPrizes = () => {
        if (!file) {
            setFileError(true)
            return;
        }
        const formData = new FormData();
        formData.append("companyId", initialSearchPrizeData.companyId);
        formData.append("filename", file);
        dispatch(addPrizes(formData)).then(() => {
            setIsUploadOpen(false);
            dispatch(fetchPrizes(requestData));
            updateFile(null);
            setFileError(false);
        });
    }

    const openFileUploadModal = () => {
        setFileError(false);
        setIsUploadOpen(true);
    }

    return (
        <div className="prizes">
            <div className="prizes__header">
                <div className="prizes__header--left">
                    <Search search={search}/>
                </div>

                <div className="prizes__header--right">
                    <Button type="primary" customClass="mr-10" onClick={() => openFileUploadModal()}>
                        Import prizes
                    </Button>
                    <Button type="primary" onClick={() => toggle(METHOD_ADD)}>
                        Add new
                    </Button>
                </div>
            </div>

            <PrizesTable
                data={prizes}
                isLoading={status === STATUS_LOADING}
                totalCount={totalCount}
                onPageChange={onPageChange}
                currentPage={requestData.pageNumber + 1}
                editPrize={editPrize}
                deletePrize={deleteAlert}
            />

            <Modal
                isOpen={isModalOpen}
                setIsOpen={setIsOpen}
                title={method === METHOD_EDIT ? "Edit prize" : "Add new prize"}
            >
                <Tabs tabs={tabs} type="small"/>

                <div className="modal__footer">
                    {
                        method === METHOD_EDIT ? (
                            <ModalFooterData prize={prize}/>
                        ) : ""
                    }
                    <div className="modal__footer--btns">
                        <Button type="secondary" onClick={() => toggle(method)}>Cancel</Button>
                        <Button type="primary" onClick={() => save()}
                                isLoading={prizeStatus === STATUS_LOADING}>Save</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isUploadOpen}
                setIsOpen={setIsUploadOpen}
                title="Upload prizes"
                customClass="import-prizes-modal"
            >
                <div className="import-prizes">
                    <FileUpload
                        file={file ? file.name : ""}
                        error={fileError}
                        handleChange={onFileChange}
                    />
                </div>


                <div className="modal__footer">
                    <div className="modal__footer--btns">
                        <Button type="secondary" onClick={() => setIsUploadOpen(false)}>Cancel</Button>
                        <Button type="primary" onClick={() => importPrizes()} isLoading={importPrizesStatus === STATUS_LOADING}>Save</Button>
                    </div>
                </div>
            </Modal>

            <Confirm
                isOpen={isAlertOpen}
                setIsOpen={setIsAlertOpen}
                title="Remove prize"
                text="Are you sure you want to remove this prize?"
                buttonText="Yes"
                type="danger"
                action={deletePrize}
            />
        </div>
    );
}

export default Prizes;
