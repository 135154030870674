import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {createBatch, deleteBatch, putBatch} from "../api/batches";
import {Batch} from "../../interfaces/Batch";
import {modal} from "./notificationSlice";
import {STATUS_FAILED, STATUS_IDLE, STATUS_LOADING} from "../../constants/constants";
import { parseApiErrors } from "../../util/util";
import { RootState } from "../store";

interface DuplicateCodesResponse {
    batchId: number;
    savedCodesCount: number;
    sentCodesCount: number;
}

export interface BatchState {
    data: Batch | DuplicateCodesResponse | null;
    status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING;
    errors: Record<string, boolean> | null;
}

const initialState: BatchState = {
    data: null,
    status: STATUS_IDLE,
    errors: null
};

const batchSlice = createSlice({
    name: "batch",
    initialState,
    reducers: {
        createBatchStarted: (state) => {
            state.status = STATUS_LOADING;
            state.errors = null;
        },
        createBatchSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data;
            } else {
                state.data = initialState.data;
            }

        },
        createBatchError: (state, action) => {
            state.status = STATUS_FAILED;
            state.errors = action.payload;
            console.log(action.payload.message);
        },
        updateBatchStarted: (state) => {
            state.status = STATUS_LOADING;
            state.errors = null;
        },
        updateBatchSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data;
            } else {
                state.data = initialState.data;
            }

        },
        updateBatchError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.message);
        },
        deleteBatchStarted: (state) => {
            state.status = STATUS_LOADING;
        },
        deleteBatchSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data;
            } else {
                state.data = initialState.data;
            }

        },
        deleteBatchError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.message);
        },
        resetBatch: (state) => {
            state.status = STATUS_IDLE;
            state.data = null;
            state.errors = null;
        },
    },
});

const {
    createBatchStarted,
    createBatchSuccess,
    createBatchError,
    updateBatchStarted,
    updateBatchSuccess,
    updateBatchError,
    deleteBatchStarted,
    deleteBatchSuccess,
    deleteBatchError,
    resetBatch,
} = batchSlice.actions;

export default batchSlice.reducer;

export const selectHasDuplicateCodes = (state: RootState) => {
    if (!state.batch.data) {
        return false;
    }

    const batch = state.batch.data as DuplicateCodesResponse;

    return batch.savedCodesCount !== batch.sentCodesCount;
};

export const selectDuplicateCodesInfo = (state: RootState) => {
    return state.batch.data ? state.batch.data as DuplicateCodesResponse : null;
};

export const addBatch = (data: FormData) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(createBatchStarted());
            const result = await createBatch(data);
            dispatch(createBatchSuccess(result.data));
            dispatch(modal.notify({
                    message: "Batch successfully imported.",
                    type: "success"
                })
            );
        } catch (error: any) {
            const validationErrors = parseApiErrors(error);

            dispatch(createBatchError(validationErrors));

            if (!validationErrors) {
                dispatch(modal.notify({
                    message: `Error: ${error.response.data.message}`,
                    type: "error"
                }));
            }
        }
    }
}

export const updateBatch = (data: Batch, id: number) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(updateBatchStarted());
            const result = await putBatch(data, id);
            dispatch(updateBatchSuccess(result.data));
        } catch (error) {
            dispatch(updateBatchError(error))
        }
    }
}

export const removeBatch = (id: number) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(deleteBatchStarted());
            const result = await deleteBatch(id);
            dispatch(deleteBatchSuccess(result.data));
        } catch (error) {
            dispatch(deleteBatchError(error))
        }
    }
}

export const emptyBatch = () => {
    return (dispatch: Dispatch) => dispatch(resetBatch());
};


