import React, {useEffect, useState} from "react";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableBody from "../../../shared/Table/TableBody";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableRow from "../../../shared/Table/TableRow";
import TableCell from "../../../shared/Table/TableCell";
import {Column} from "../../../../interfaces/Column";
import {Prize} from "../../../../interfaces/Prize";
import {useAppDispatch, useAppSelector} from "../../../../data/hooks";
import Pagination from "../../../shared/Pagination/Pagination";
import {fetchPrizes} from "../../../../data/slices/prizesSlice";
import Checkbox from "../../../shared/Checkbox/Checkbox";
import {METHOD_ADD, METHOD_DELETE, STATUS_LOADING} from "../../../../constants/constants";

const initialSearchPrizeData = {
    companyId: localStorage.getItem("companyId") ?? "",
    perPage: 8,
    pageNumber: 0,
    sortBy: "id",
    order: "DESC",
    searchTerm: ""
}

type Props = {
    poolPrizes: Array<Prize>,
    updatePoolPrizes: Function,
}

const PrizesTable = ({poolPrizes, updatePoolPrizes}: Props) => {
    const prizes: Array<Prize> = useAppSelector((state: any) => state.prizes.data);
    const totalCount: number = useAppSelector((state: any) => state.prizes.dataCount);
    const status: string = useAppSelector((state: any) => state.prizes.status);
    const [requestData, changeRequestData] = useState(initialSearchPrizeData);
    const dispatch = useAppDispatch();


    useEffect(() => {
        dispatch(fetchPrizes(requestData));
    }, [dispatch, requestData]);


    const columns: Array<Column> = [
        {
            label: "Select",
            value: "select",
            render: (data, columnValue, index: number) => (
                <Checkbox
                    key={index}
                    checked={checkIfPrizeInPool(data.id)}
                    handleChange={handleCheckboxChange}
                    value={data.id}
                />
            )
        },
        {
            label: "Title",
            value: "title",
        },
        {
            label: "Image",
            value: "gameImage",
            render: (data, columnValue) => (
                <img src={data[columnValue]} alt="Prize"/>
            )
        },
        {
            label: "Avaliable",
            value: "available"
        },
    ];
    const handleCheckboxChange = (e: any) => {
        const method = e.target.checked ? METHOD_ADD : METHOD_DELETE;
        const prize = prizes.find((prize) => prize.id === Number(e.target.value));
        if (method === METHOD_DELETE && prize) {
            const prizesInsidePool = poolPrizes.filter((poolPrize) => poolPrize.id !== prize.id);
            updatePoolPrizes(prizesInsidePool);
            return;
        }

        const prizesInsidePool = [...poolPrizes];
        if (prize) {
            prizesInsidePool.unshift(prize);
            updatePoolPrizes(prizesInsidePool);
        }
    }

    const checkIfPrizeInPool = (id: number) => {
        return poolPrizes.some((prize) => prize.id === id);
    }

    const onPageChange = (pageNumber: number) => {
        const page = pageNumber !== 0 ? pageNumber - 1 : pageNumber;
        changeRequestData({...requestData, pageNumber: page})
    }


    const renderTableCell = (row: any, column: any, columnIndex: number, rowIndex: number) => {
        return (
            <TableCell key={columnIndex}>
                {
                    column.render ? (
                        column.render(row, column.value, rowIndex)
                    ) : (
                        <span className="elipsis-200">
                            {row[column.value]}
                        </span>
                    )
                }
            </TableCell>
        )
    }

    return (
        <ErrorBoundary>
            <Loader isLoading={status === STATUS_LOADING} height={550}>
                <Table>
                    <TableHead>
                        {columns.map(({label}, index) => <TableHeadCell key={index} heading={label}/>)}
                    </TableHead>
                    <TableBody>
                        {
                            prizes.length > 0 ? (
                                prizes.map((row, index) => (
                                    <TableRow key={index}>
                                        {
                                            columns.map((column, i) => (
                                                renderTableCell(row, column, i, index)
                                            ))
                                        }
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <td className="no-data" colSpan={columns.length}>No prizes available</td>
                                </TableRow>
                            )

                        }
                    </TableBody>
                    <Pagination
                        colSpan={columns.length}
                        totalCount={totalCount}
                        showPerPage={8}
                        currentPage={requestData.pageNumber + 1}
                        onPageChange={onPageChange}
                    />
                </Table>
            </Loader>
        </ErrorBoundary>
    );
};

export default PrizesTable;
