import {createSlice, Dispatch} from "@reduxjs/toolkit";

import {modal} from "./notificationSlice";
import {STATUS_FAILED, STATUS_IDLE, STATUS_LOADING} from "../../constants/constants";
import {importPrizes} from "../api/prizes";
import {RootState} from "../store";

export interface ImportPrizesState {
    data: string | null;
    status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: ImportPrizesState = {
    data: null,
    status: STATUS_IDLE
};

const importPrizesSlice = createSlice({
    name: "importPrizes",
    initialState,
    reducers: {
        immportPrizesStarted: (state) => {
            state.status = STATUS_LOADING;
        },
        immportPrizesSuccess: (state, action) => {
            state.status = STATUS_IDLE;
            if (action.payload.data) {
                state.data = action.payload.data;
            } else {
                state.data = initialState.data;
            }

        },
        immportPrizesError: (state, action) => {
            state.status = STATUS_FAILED;
            console.log(action.payload.message);
        }
    },
});

const {
    immportPrizesStarted,
    immportPrizesSuccess,
    immportPrizesError,
} = importPrizesSlice.actions;

export const status = (state: RootState) => state.importPrizes.status;


export default importPrizesSlice.reducer;

export const addPrizes = (data: FormData) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(immportPrizesStarted());
            const result = await importPrizes(data);
            dispatch(immportPrizesSuccess(result.data));
            dispatch(modal.notify({
                    message: "Prizes successfully imported.",
                    type: "success"
                })
            );
        } catch (error) {
            dispatch(immportPrizesError(error))
        }
    }
}


