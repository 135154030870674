import React from "react";

type Props = {
    children: React.ReactNode
}
const TableHead: React.FC<Props> = ({children}) => {
    return (
        <thead>
            <tr>
                {children}
            </tr>
        </thead>
    );
};

export default TableHead;
