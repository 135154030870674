import React from "react";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableBody from "../../../shared/Table/TableBody";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableRow from "../../../shared/Table/TableRow";
import TableCell from "../../../shared/Table/TableCell";
import {Column} from "../../../../interfaces/Column";
import {ReactComponent as Delete} from "../../../../assets/icons/delete.svg";
import {calculateCampaignEndDate, formatCampaignType, getDate} from "../../../../util/util";
import {Pool} from "../../../../interfaces/Pool";

type Props = {
    data: Array<any>,
    pool: Pool,
    removeCampaign: Function,
    isLoading: boolean,
}

const InCampaignTable: React.FC<Props> = ({data, pool, removeCampaign, isLoading}: Props) => {
    const columns: Array<Column> = [
        {
            label: "Campaign name",
            value: "name",
        },
        {
            label: "Campaign Type",
            value: "campaignType",
            render: (data: any, columnValue: string) => (
                formatCampaignType(data[columnValue])
            )
        },
        {
            label: "Campaign start date",
            value: "startDate",
            render: (data: any, columnValue: string) => {
                if (data[columnValue]) {
                    return getDate(data[columnValue].replace("T", " "))
                }
                return "N/A";
            }
        },
        {
            label: "Campaign end date",
            value: "endDate",
            render: (data: any, columnValue: string) => {
                if (data.startDate && pool.durationInDays) {
                    return calculateCampaignEndDate(data.startDate, pool.durationInDays)
                }
                return "N/A";
            }
        },
        {
            label: "",
            value: "delete",
            render: (data: any) => (
                <Delete onClick={() => removeCampaign(data.id)}/>
            )
        },
    ];


    const renderTableCell = (row: any, column: any, index: number) => {
        return (
            <TableCell key={index}>
                {
                    column.render ? (
                        column.render(row, column.value)
                    ) : (
                        <span className="elipsis-200">
                            {row[column.value]}
                        </span>
                    )
                }
            </TableCell>
        )
    }


    return (
        <ErrorBoundary>
            <Loader isLoading={isLoading}>
                <Table className="no-image-table white-space-normal">
                    <TableHead>
                        {columns.map(({label}, index) => <TableHeadCell key={index} heading={label}/>)}
                    </TableHead>
                    <TableBody>
                        {
                            data.length > 0 ? (
                                data.map((row, index) => (
                                    <TableRow key={index}>
                                        {
                                            columns.map((column, i) => (
                                                renderTableCell(row, column, i)
                                            ))
                                        }
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <td className="no-data" colSpan={columns.length}>No data</td>
                                </TableRow>
                            )
                        }
                    </TableBody>
                    {/*<Pagination*/}
                    {/*    colSpan={columns.length}*/}
                    {/*    total={data.length}*/}
                    {/*    showPerPage={5}*/}
                    {/*    currentPage={1}*/}
                    {/*/>*/}
                </Table>
            </Loader>
        </ErrorBoundary>
    );
};

export default InCampaignTable;
