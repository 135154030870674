import React, {useState} from "react";

import "./Search.scss";
import {ReactComponent as SearchIcon} from "../../../assets/icons/search.svg";
import {ReactComponent as ClearIcon} from "../../../assets/icons/close.svg";

type Props = {
    search: Function,
    placeholder?: string
}

const Search = ({search, placeholder}: Props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [showClearIcon, setClearIcon] = useState(false);

    const searchData = (e: any) => {
        if (e.key === "Enter") {
            search(searchTerm);
            if (searchTerm) {
                setClearIcon(true);
            } else {
                setClearIcon(false);
            }
        }
    }

    const clear = () => {
        setSearchTerm("");
        search("");
        setClearIcon(false);
    }

    const searchDataOnClick = () => {
        search(searchTerm);
        setClearIcon(true);
    }

    return (
        <div className="search">
            <input type="text" placeholder={placeholder ? placeholder : "Search..."} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                   onKeyPress={searchData}/>

            {
                showClearIcon ? (
                    <ClearIcon onClick={() => clear()}/>
                ) : (
                    <SearchIcon onClick={() => searchDataOnClick()}/>
                )
            }
        </div>
    );
};

export default Search;
